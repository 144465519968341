import { useCallback } from "react";
import { GridToolbar, DataGridPro, GridRowParams } from "@mui/x-data-grid-pro";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { StaffRecord } from "../../interfaces/interfaces";
import { staffColumns } from "../../libraries/columns";
import { PERMISSIONS } from "../../libraries/objects";
import { loggedInStaffAtom, StaffFormAtom } from "../../recoil/staffAtoms";

type Props = {
  staff: StaffRecord[];
  setOpen: (prop: boolean) => void;
};

export default function StaffTable({ staff, setOpen }: Props) {
  const window = useWindowDimensions();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const setStaffForm = useSetRecoilState(StaffFormAtom);

  const handleOpen = useCallback(
    (data: GridRowParams) => {
      const userPermissions = loggedInStaff ? loggedInStaff.sitePermissions : [];
      if (!userPermissions.includes(PERMISSIONS.EDIT_STAFF)) return;
      setOpen(true);
      setStaffForm({ ...data.row, email: data.row.id });
    },
    [loggedInStaff, setOpen, setStaffForm]
  );

  return (
    <>
      {staff && (
        <div style={{ height: window.height - 300, width: "100%" }}>
          <DataGridPro
            rows={staff}
            columns={staffColumns}
            rowHeight={34}
            onRowClick={handleOpen}
            disableSelectionOnClick
            components={{
              Toolbar: GridToolbar,
            }}
            getRowClassName={(params) => `row${params.row.terminated ? "NotActive" : "Active"}`}
          />
        </div>
      )}
    </>
  );
}
