import { useContext } from "react";
import { MenuItem, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { loggedInStaffAtom } from "../../recoil/staffAtoms";
import { useRecoilValue } from "recoil";
import { AuthContext } from "../../providers/AuthProvider";
// import SelectOptions from "./SelectOptions";

type Props = {
  handleClose: () => void;
};

export default function MenuDropdown({ handleClose }: Props) {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const { logout } = useContext(AuthContext);

  return (
    <>
      {/* can set permissions here */}
      <>
        <Box sx={{ padding: 2 }}>
          {/* <SelectOptions /> */}
          <MenuItem onClick={handleClose}>
            <Link to="/staff" className="navLinkMenu">
              Staff
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/homerooms" className="navLinkMenu">
              Homerooms
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/settings" className="navLinkMenu">
              Settings
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/student-table" className="navLinkMenu">
              Students
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/newRegistration" className="navLinkMenu">
              Form
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/accountability-roster" className="navLinkMenu">
              Accountability Roster
            </Link>
          </MenuItem>
        </Box>
      </>
    </>
  );
}
