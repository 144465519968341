import { SectionInterface, FieldInterface } from "../interfaces/interfaces";
import { fieldsAtom, sectionsAtom } from "./atoms";
import { GetRecoilValue } from "recoil";
import { loggedInStaffAtom } from "./staffAtoms";

type Props = {
  get: GetRecoilValue;
};

export const sectionsAndFieldsGetter = ({ get }: Props) => {
  const sections = get(sectionsAtom);
  const fields = get(fieldsAtom);
  const loggedInStaff = get(loggedInStaffAtom);
  if (!sections || !fields) return;
  const tempSectionsArray: SectionInterface[] = [];
  sections.forEach((section) => {
    if (!loggedInStaff && section.showCaregiver === false) return;
    const tempFieldsArray: FieldInterface[] = [];
    fields.forEach((field) => {
      if (field.sectionId === section.id) {
        tempFieldsArray.push(field);
      }
    });
    tempSectionsArray.push({ ...section, fields: tempFieldsArray });
  });
  return tempSectionsArray;
};

export const registrationFormGetter = ({ get }: Props) => {
  const fields = get(fieldsAtom);
  const sections = get(sectionsAtom);
  if (!fields || !sections) return;
  const tempSectionsArray: SectionInterface[] = [];
  sections.forEach((s) => {
    let showInForm = false;
    const tempFieldsArray: FieldInterface[] = [];
    fields.forEach((f) => {
      if (f.sectionId === s.id && f.inForm) {
        showInForm = true;
        const tempObj = { ...f };
        if (!tempObj.tableDefault) {
          tempObj.tableDefault = false;
        }
        tempFieldsArray.push(tempObj);
      }
    });
    if (showInForm) {
      tempSectionsArray.push({ ...s, fields: tempFieldsArray });
    }
  });
  return tempSectionsArray;
};
