import { useState } from "react";
import { db } from "../firebase";
import { Button, Select, MenuItem, Box, Typography, SelectChangeEvent } from "@mui/material";
import { doc, getDocs, collection, updateDoc, setDoc } from "firebase/firestore";
import { COLLECTIONS } from "../libraries/objects";

const DeletePrimaryCaregiverIdField = () => {
  const [selectedCollection, setSelectedCollection] = useState<string>("none");

  const deletePrimaryCaregiverId = async () => {
    const studentsCollectionRef = collection(db, "students");
    const snapshot = await getDocs(studentsCollectionRef);
    snapshot.forEach((student) => {
      const newStudentObj = { ...student.data() };
      if("primaryCaregiverId" in newStudentObj){
      delete newStudentObj.primaryCaregiverId;
    }
      const studentDocRef = doc(db, "students", student.id);
      setDoc(studentDocRef, newStudentObj);
    });
  };

  return (
    <>
      <Box sx={{ margin: 4 }}>
        <Button sx={{ mr: 2 }} onClick={deletePrimaryCaregiverId} variant="contained">
          Delete Primary Caregiver Id in Students Collection
        </Button>
      </Box>
    </>
  );
};

export default DeletePrimaryCaregiverIdField;
