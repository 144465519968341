import React, { useEffect } from "react";
import { Box, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { sectionsAndFieldsAtom } from "../../recoil/atoms";
import { selectedDialogSectionAtom } from "../../recoil/studentAtoms";
import { useRecoilValue, useRecoilState } from "recoil";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { loggedInStaffAtom } from "../../recoil/staffAtoms";

type FormState = EventTarget & {
  name: string;
};

function SideMenu() {
  const sectionsAndFields = useRecoilValue(sectionsAndFieldsAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const [selectedDialogSection, setSelectedDialogSection] =
    useRecoilState(selectedDialogSectionAtom);
  const { width } = useWindowDimensions();
  const menuHeight = width <= 600 ? "150px" : "100%";

  const handleClick = (event: any) => {
    const formState = event.target as FormState;
    setSelectedDialogSection(formState.name);
  };

  useEffect(() => {
    if (selectedDialogSection || !sectionsAndFields) return;
    setSelectedDialogSection(sectionsAndFields[0].id);
  }, [sectionsAndFields, selectedDialogSection, setSelectedDialogSection]);

  return (
    <>
      <Box sx={{ backgroundColor: "#B930E6", height: menuHeight, overflow: "scroll" }}>
        <List>
          {sectionsAndFields &&
            sectionsAndFields.map((section) => (
              <ListItemButton
                divider={true}
                key={section.id}
                onClick={handleClick}
                component="button"
                name={section.id}
                selected={selectedDialogSection === section.id}
                sx={{ width: "100%" }}
              >
                <ListItemIcon sx={{ pointerEvents: "none" }}>
                  <AutoAwesomeIcon sx={{ color: "#F6E649", pointerEvents: "none" }} />
                </ListItemIcon>
                <ListItemText
                  primary={section.name}
                  sx={{ color: "#fff", pointerEvents: "none" }}
                />
              </ListItemButton>
            ))}
          <ListItemButton
            divider={true}
            onClick={handleClick}
            component="button"
            name={"additionalContacts"}
            selected={selectedDialogSection === "additionalContacts"}
            sx={{ width: "100%" }}
          >
            <ListItemIcon sx={{ pointerEvents: "none" }}>
              <AutoAwesomeIcon sx={{ color: "#F6E649", pointerEvents: "none" }} />
            </ListItemIcon>
            <ListItemText
              primary="Additional Contacts"
              sx={{ color: "#fff", pointerEvents: "none" }}
            />
          </ListItemButton>
          {loggedInStaff && (
            <ListItemButton
              divider={true}
              onClick={handleClick}
              component="button"
              name={"comments"}
              selected={selectedDialogSection === "comments"}
              sx={{ width: "100%" }}
            >
              <ListItemIcon sx={{ pointerEvents: "none" }}>
                <AutoAwesomeIcon sx={{ color: "#F6E649", pointerEvents: "none" }} />
              </ListItemIcon>
              <ListItemText primary="Comments" sx={{ color: "#fff", pointerEvents: "none" }} />
            </ListItemButton>
          )}
          <ListItemButton
            divider={true}
            onClick={handleClick}
            component="button"
            name={"files"}
            selected={selectedDialogSection === "files"}
            sx={{ width: "100%" }}
          >
            <ListItemIcon sx={{ pointerEvents: "none" }}>
              <AutoAwesomeIcon sx={{ color: "#F6E649", pointerEvents: "none" }} />
            </ListItemIcon>
            <ListItemText primary="Files" sx={{ color: "#fff", pointerEvents: "none" }} />
          </ListItemButton>
        </List>
      </Box>
    </>
  );
}

export default SideMenu;
