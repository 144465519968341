import { SyntheticEvent } from "react";
import { TextField } from "@mui/material";

type FormState = EventTarget & {
  name: string;
  value: string;
};

type Props = {
  value: string;
  setValue: (value: string) => void;
  type: string;
};

export default function TextEdit({ value, setValue, type }: Props) {
  const multiline = type === "textarea" ? true : false;
  const handleChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setValue(formState.value);
  };
  return (
    <TextField
      autoFocus
      id="edit"
      type={type}
      fullWidth
      value={value}
      variant="standard"
      onChange={handleChange}
      multiline={multiline}
      rows={4}
    />
  );
}
