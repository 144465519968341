import { atom } from "recoil";
import { Staff, StaffRecord, ChangeLog } from "../interfaces/interfaces";
import { BLANK_STAFF_FORM } from "../libraries/blankForms";

export const staffAtom = atom<StaffRecord[]>({
  key: "staff",
  default: [],
});

export const loggedInStaffAtom = atom<StaffRecord | null>({
  key: "loggedInStaff",
  default: null,
});

export const staffResetAtom = atom({
  key: "staffReset",
  default: false,
});

export const StaffFormAtom = atom<Staff | StaffRecord>({
  key: "staffForm",
  default: BLANK_STAFF_FORM,
});

export const staffChangeLogsAtom = atom<ChangeLog[]>({
  key: "staffChangeLogs",
  default: [],
});
