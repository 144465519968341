import React from "react";
import { FormControlLabel, Switch, Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { StaffFormAtom } from "../../recoil/staffAtoms";

type Props = {
  handleSwitchChange: ({ field, checked }: { field: string; checked: boolean }) => void;
};

function StaffSwitches({ handleSwitchChange }: Props) {
  const staffForm = useRecoilValue(StaffFormAtom);
  return (
    <>
      <Box sx={{mt: 2}}>
        <FormControlLabel
          control={
            <Switch
              onChange={(e) =>
                handleSwitchChange({ field: "terminated", checked: e.target.checked })
              }
              checked={staffForm.terminated || false}
            />
          }
          label="Are They Terminated?"
        />
        <FormControlLabel
          control={
            <Switch
              onChange={(e) =>
                handleSwitchChange({ field: "nonExempt", checked: e.target.checked })
              }
              checked={staffForm.nonExempt || false}
            />
          }
          label="Are They Non Exempt?"
        />
      </Box>
    </>
  );
}

export default StaffSwitches;
