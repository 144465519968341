import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { sectionsAndFieldsAtom } from "../../recoil/atoms";
import { loggedInStaffAtom } from "../../recoil/staffAtoms";
import { Button, Typography, Box } from "@mui/material";
import Section from "./Section";
import AdditionalContactDisplay from "./AdditionalContactDisplay";
import { PERMISSIONS } from "../../libraries/objects";
import { FieldInterface, StudentRecord } from "../../interfaces/interfaces";
import { selectedDialogSectionAtom } from "../../recoil/studentAtoms";
import CommentsContainer from "./CommentsContainer";
import FileList from "./FileList";
import FileUpload from "./FileUpload";

type Props = {
  selectedStudent: StudentRecord;
  setSelectedStudent: (value: StudentRecord | null) => void;
  selectedField: FieldInterface | null;
  setSelectedField: (value: FieldInterface | null) => void;
  setAdditionalContactOpen: (value: boolean) => void;
  setAdditionalContactIndex: (value: number | null) => void;
  setDeleteContactConfirmation: (value: boolean) => void;
};

function SectionsRouter({
  selectedStudent,
  setSelectedStudent,
  selectedField,
  setSelectedField,
  setAdditionalContactIndex,
  setAdditionalContactOpen,
  setDeleteContactConfirmation,
}: Props) {
  const sectionsAndFields = useRecoilValue(sectionsAndFieldsAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const selectedDialogSection = useRecoilValue(selectedDialogSectionAtom);

  const handleAddContact = () => {
    setAdditionalContactOpen(true);
  };

  useEffect(() => {
    if (
      !loggedInStaff?.sitePermissions?.includes(PERMISSIONS.SEE_ADMIN_COMMENTS) &&
      selectedStudent.comments
    ) {
      const comments = selectedStudent.comments.filter((comment) => !comment.adminOnly);
      setSelectedStudent({ ...selectedStudent, comments: comments });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedStudent, loggedInStaff]);
  return (
    <>
      {sectionsAndFields &&
        sectionsAndFields.map((section) => (
          <Box key={section.id}>
            {selectedDialogSection === section.id && (
              <Section
                section={section}
                selectedStudent={selectedStudent}
                setSelectedField={setSelectedField}
              />
            )}
          </Box>
        ))}
      {selectedDialogSection === "additionalContacts" && (
        <>
          {selectedStudent.additionalContacts &&
            selectedStudent.additionalContacts.map((contact, index) => (
              <AdditionalContactDisplay
                additionalContact={contact}
                index={index}
                key={"additionalContact" + index}
                setAdditionalContactOpen={setAdditionalContactOpen}
                setAdditionalContactIndex={setAdditionalContactIndex}
                setDeleteContactConfirmation={setDeleteContactConfirmation}
              />
            ))}
          {loggedInStaff && loggedInStaff.sitePermissions?.includes(PERMISSIONS.EDIT_STUDENTS) && (
            <Button sx={{ mt: 2 }} variant="contained" onClick={handleAddContact}>
              Add Additional Contact
            </Button>
          )}
        </>
      )}
      {selectedDialogSection === "comments" && (
        <CommentsContainer
          selectedStudent={selectedStudent}
          setSelectedStudent={setSelectedStudent}
          commentsArray={selectedStudent.comments}
        />
      )}
      {selectedDialogSection === "files" && (
        <>
          <Typography sx={{ mt: 2 }} variant="h4">
            Files
          </Typography>
          {selectedStudent?.files && (
            <FileList selectedStudent={selectedStudent} setSelectedStudent={setSelectedStudent} />
          )}
          <FileUpload selectedStudent={selectedStudent} setSelectedStudent={setSelectedStudent} />
        </>
      )}
    </>
  );
}

export default SectionsRouter;
