import { useCallback } from "react";
import { HomeroomRecord, StaffRecord } from "../interfaces/interfaces";
import { homeroomsAtom } from "../recoil/homeroomAtoms";
import { staffAtom } from "../recoil/staffAtoms";
import { loggedInStaffAtom } from "../recoil/staffAtoms";
import { useRecoilValue } from "recoil";
import useAddDoc from "./useAddDoc";

type Props = {
  form: StaffRecord;
};

type GetHomeroomNameProps = {
  changedHomerooms: string[];
  homeroomRecords: HomeroomRecord[];
};

type StringKeys = [
  "department",
  "email",
  "firstName",
  "jobTitle",
  "lastName",
  "permission",
  "ptoPolicy",
  "startDate",
  "termDate"
];

const READABLE_KEYS = {
  department: "Department",
  email: "Email",
  firstName: "First Name",
  jobTitle: "Job Title",
  lastName: "Last Name",
  permission: "Permission",
  ptoPolicy: "PTO Policy",
  startDate: "Start Date",
  termDate: "Termination Date",
};

type GetHomeroomName = ({ changedHomerooms, homeroomRecords }: GetHomeroomNameProps) => string[];

const getHomeroomName: GetHomeroomName = ({ changedHomerooms, homeroomRecords }) => {
  const namesArray: string[] = [];
  changedHomerooms.forEach((homeroom) => {
    const currentHomeroom = homeroomRecords.find(
      (currentHomeroom) => currentHomeroom.id === homeroom
    );
    if (currentHomeroom) {
      namesArray.push(`${currentHomeroom.name}`);
    }
  });
  return namesArray;
};

const useStaffChangeLog = () => {
  const homerooms = useRecoilValue(homeroomsAtom);
  const staff = useRecoilValue(staffAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const { sendRequest: addDoc } = useAddDoc();
  const addChangeLog = useCallback(
    async ({ form }: Props) => {
      const STRING_KEYS: StringKeys = [
        "department",
        "email",
        "firstName",
        "jobTitle",
        "lastName",
        "permission",
        "ptoPolicy",
        "startDate",
        "termDate",
      ];
      try {
        if (form && loggedInStaff) {
          const now = new Date();
          const dateTime = `${now.toLocaleDateString()} at ${now.toLocaleTimeString()} `;
          const currentDoc = staff.find((staffMember) => staffMember.id === form.id);
          if (!currentDoc) return;
          let messages: string[] = [];
          STRING_KEYS.forEach((key) => {
            if (form[key] !== currentDoc[key]) {
              messages.push(
                `changed the ${READABLE_KEYS[key]} from ${currentDoc[key]} to ${form[key]}`
              );
            }
          });

          if (form.terminated !== currentDoc.terminated) {
            messages.push(
              `changed the termination status from ${
                currentDoc.terminated ? "Terminated" : "Not Terminated"
              } to ${form.terminated ? "Terminated" : "Not Terminated"}`
            );
          }

          if (form.nonExempt !== currentDoc.nonExempt) {
            messages.push(
              `changed the exempt status from ${
                currentDoc.nonExempt ? "Non Exempt" : "Exempt"
              } to ${form.nonExempt ? "Non Exempt" : "Exempt"}`
            );
          }

          const addedHomerooms = form.homerooms.filter(
            (homeroom) => !currentDoc.homerooms.includes(homeroom)
          );
          const removedHomerooms = currentDoc.homerooms.filter(
            (homeroom) => !form.homerooms.includes(homeroom)
          );
          if (addedHomerooms) {
            const addedHomeroomNames = getHomeroomName({
              homeroomRecords: homerooms,
              changedHomerooms: addedHomerooms,
            });
            addedHomeroomNames.forEach((addedHomeroomName) => {
              messages.push(`added ${addedHomeroomName}`);
            });
          }
          if (removedHomerooms) {
            const removedHomeroomNames = getHomeroomName({
              homeroomRecords: homerooms,
              changedHomerooms: removedHomerooms,
            });
            removedHomeroomNames.forEach((removedHomeroomName) => {
              messages.push(`removed ${removedHomeroomName}`);
            });
          }

          const addedPermissions = form.sitePermissions.filter(
            (permission) => !currentDoc.sitePermissions.includes(permission)
          );
          const removedPermissions = currentDoc.sitePermissions.filter(
            (permission) => !form.sitePermissions.includes(permission)
          );
          if (addedPermissions) {
            addedPermissions.forEach((permission) => {
              messages.push(`added ${permission}`);
            });
          }
          if (removedPermissions) {
            removedPermissions.forEach((permission) => {
              messages.push(`removed ${permission}`);
            });
          }

          if (messages.length > 0) {
            let finalMessage = `${loggedInStaff.firstName} ${loggedInStaff.lastName} made the following changes at ${dateTime}: `;
            messages.forEach((message, index) => {
              if (index + 1 === messages.length) {
                finalMessage = finalMessage + `\n - ${message} `;
              } else {
                finalMessage = finalMessage + `\n - ${message} and, `;
              }
            });
            addDoc({
              col: "staffChangeLogs",
              data: { message: finalMessage, authorId: loggedInStaff.id, recordId: form.id },
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    [homerooms, loggedInStaff, staff, addDoc]
  );
  return { addChangeLog };
};

export default useStaffChangeLog;
