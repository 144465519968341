import { useCallback } from "react";
import { GradeRecord, HomeroomRecord, StaffRecord } from "../interfaces/interfaces";
import { homeroomsAtom } from "../recoil/homeroomAtoms";
import { gradesAtom } from "../recoil/gradeAtoms";
import { staffAtom } from "../recoil/staffAtoms";
import { loggedInStaffAtom } from "../recoil/staffAtoms";
import { useRecoilValue } from "recoil";
import useAddDoc from "./useAddDoc";
import { compact } from "../libraries/functions";

type Props = {
  form: HomeroomRecord;
};

type GetStaffNameProps = {
  changedStaff: string[];
  staffRecords: StaffRecord[];
};

type FormatGradeChange = {
  form: HomeroomRecord;
  grades: GradeRecord[];
  currentDoc: HomeroomRecord;
};

type GetStaffName = ({ changedStaff, staffRecords }: GetStaffNameProps) => string[];
type Falsy = undefined | false | null;

const getStaffName: GetStaffName = ({ changedStaff, staffRecords }) => {
  const namesArray: string[] = [];
  changedStaff.forEach((staffMember) => {
    const currentStaff = staffRecords.find(
      (currentStaffMember) => currentStaffMember.id === staffMember
    );
    if (currentStaff) {
      namesArray.push(`${currentStaff.firstName} ${currentStaff.lastName}`);
    }
  });
  return namesArray;
};

const formatGradeChange = ({ form, grades, currentDoc }: FormatGradeChange): string | Falsy => {
  const currentGrade = grades.find((grade) => grade.id === currentDoc.gradeId); // O(n) to O(1)
  const newGrade = grades.find((grade) => grade.id === form.gradeId);
  return (
    form.gradeId !== currentDoc.gradeId &&
    currentGrade &&
    newGrade &&
    `changed the homeroom grade from ${currentGrade.name} to ${newGrade.name}`
  );
};

const useHomeroomChangeLog = () => {
  const homerooms = useRecoilValue(homeroomsAtom);
  const grades = useRecoilValue(gradesAtom);
  const staff = useRecoilValue(staffAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const { sendRequest: addDoc } = useAddDoc();
  const addChangeLog = useCallback(
    async ({ form }: Props) => {
      try {
        if (form && loggedInStaff) {
          const now = new Date();
          const dateTime = `${now.toLocaleDateString()} at ${now.toLocaleTimeString()} `;
          const currentDoc = homerooms.find((homeroom) => homeroom.id === form.id);
          if (!currentDoc) return;
          let messages: Array<string | Falsy> = [];
          if (form.name !== currentDoc.name) {
            messages.push(`changed the homeroom name from ${currentDoc.name} to ${form.name}`);
          }
          messages.push(formatGradeChange({ form, grades, currentDoc }));

          const addedStaff = form.staff.filter(
            (staffMember) => !currentDoc.staff.includes(staffMember)
          );
          const removedStaff = currentDoc.staff.filter(
            (staffMember) => !form.staff.includes(staffMember)
          );
          if (addedStaff) {
            const addedStaffNames = getStaffName({ staffRecords: staff, changedStaff: addedStaff });
            addedStaffNames.forEach((addedStaffName) => {
              messages.push(`added ${addedStaffName}`);
            });
          }
          if (removedStaff) {
            const removedStaffNames = getStaffName({
              staffRecords: staff,
              changedStaff: removedStaff,
            });
            removedStaffNames.forEach((removedStaffName) => {
              messages.push(`removed ${removedStaffName}`);
            });
          }
          messages = compact(messages);

          if (messages.length > 0) {
            let finalMessage = `${loggedInStaff.firstName} ${loggedInStaff.lastName} made the following changes at ${dateTime}: `;
            messages.forEach((message, index) => {
              if (index + 1 === messages.length) {
                finalMessage = finalMessage + `\n - ${message} `;
              } else {
                finalMessage = finalMessage + `\n - ${message} and, `;
              }
            });
            addDoc({
              col: "homeroomChangeLogs",
              data: { message: finalMessage, authorId: loggedInStaff.id, recordId: form.id },
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    [grades, homerooms, loggedInStaff, staff, addDoc]
  );
  return { addChangeLog };
};

export default useHomeroomChangeLog;
