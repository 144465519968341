import { Container } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import RegistrationFormContainer from "../components/RegistrationForm/RegistrationFormContainer";

const RegistrationFormPage = () => {
  return (
    <>
      <Navbar />
      <Container maxWidth="md" sx={{ minHeight: "100vh", mt: 8, pt: 2 }}>
        <RegistrationFormContainer />
      </Container>
    </>
  );
};

export default RegistrationFormPage;
