import { useEffect } from "react";
import { FormControlLabel, Typography, Checkbox, Grid, Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { sectionsAndFieldsAtom } from "../../recoil/atoms";

type Props = {
  filteredFields: string[];
  setFilteredFields: (value: string[] | ((prevVar: string[]) => string[])) => void;
};

export default function TableFilters({ filteredFields, setFilteredFields }: Props) {
  const sectionsAndFields = useRecoilValue(sectionsAndFieldsAtom);

  useEffect(() => {
    if (!sectionsAndFields) return;
    const tempArray: string[] = [];
    sectionsAndFields.forEach((section) => {
      section.fields.forEach((f) => {
        if (f.tableDefault) tempArray.push(f.id);
      });
    });
    setFilteredFields(tempArray);
  }, [sectionsAndFields, setFilteredFields]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempArray = [...filteredFields];
    if (filteredFields.includes(event.target.name)) {
      const index = filteredFields.indexOf(event.target.name);
      tempArray.splice(index, 1);
    } else {
      tempArray.push(event.target.name);
    }
    setFilteredFields(tempArray);
  };

  return (
    <Box
      sx={{ height: "300px", overflow: "scroll", mb: 2, backgroundColor: "#fefefe", padding: 2 }}
    >
      {sectionsAndFields &&
        filteredFields &&
        sectionsAndFields.map((section) => (
          <Box key={section.id}>
            <Typography>{section.name}</Typography>

            <Grid container spacing={0}>
              {section.fields.map((field) => (
                <Grid key={field.id} item xs={6} sm={4} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleChange}
                        name={field.id}
                        checked={filteredFields.includes(field.id)}
                      />
                    }
                    label={<Typography className="filterLabel">{field.name}</Typography>}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
    </Box>
  );
}
