import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { fieldsAtom, sectionsAndFieldsAtom } from "../../recoil/atoms";
import { homeroomsAtom, selectedHomeroomAtom } from "../../recoil/homeroomAtoms";
import { studentsAtom } from "../../recoil/studentAtoms";
import { Typography, Box } from "@mui/material";
import { fieldRef } from "../../libraries/objects";
import { DateTime } from "luxon";
import RosterTable from "./RosterTable";
import StudentDialogContainer from "../StudentDialog/StudentDialogContainer";
import { ColumnInterface, StudentRecord } from "../../interfaces/interfaces";
import { GridValueGetterParams, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import FiltersAccordion from "./FiltersAccordion";

export default function StudentTableContainer() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStudents, setSelectedStudents] = useState<StudentRecord[]>([]);
  const [filteredStudents, setFilteredStudents] = useState<StudentRecord[]>([]);
  const [filteredFields, setFilteredFields] = useState<string[]>([]);
  const [selectedStudent, setSelectedStudent] = useState<StudentRecord | null | undefined>(null);
  const [columns, setColumns] = useState<ColumnInterface[]>([]);
  const [selectedEnrollStatus, setSelectedEnrollStatus] = useState("Actively Enrolled");
  const students = useRecoilValue(studentsAtom);
  const selectedHomeroom = useRecoilValue(selectedHomeroomAtom);
  const fields = useRecoilValue(fieldsAtom);
  const sectionsAndFields = useRecoilValue(sectionsAndFieldsAtom);
  const homerooms = useRecoilValue(homeroomsAtom);

  //filter students by homeroom and enrollment Status
  useEffect(() => {
    if (!students || !selectedHomeroom) return;
    let studentsArray = [...students];
    if (selectedEnrollStatus !== "all") {
      studentsArray = studentsArray.filter(
        (s) => s[fieldRef.enrollStatus] === selectedEnrollStatus
      );
    }
    if (selectedHomeroom !== "all") {
      studentsArray = studentsArray.filter((s) => s[fieldRef.homeroom] === selectedHomeroom);
    }
    setSelectedStudents(studentsArray);
  }, [students, selectedHomeroom, selectedEnrollStatus]);

  //filter students by search term
  useEffect(() => {
    if (!selectedStudents) return;
    if (searchTerm.length > 2) {
      const filtered = selectedStudents.filter(
        (s) =>
          String(s[fieldRef.childFirstName]).toLowerCase().includes(searchTerm.toLowerCase()) ||
          String(s[fieldRef.childLastName]).toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredStudents(filtered);
    } else {
      setFilteredStudents(selectedStudents);
    }
  }, [selectedStudents, searchTerm]);

  useEffect(() => {
    if (!filteredFields || !homerooms || !sectionsAndFields) return;

    const getCellFormat = (params: GridValueFormatterParams) => {
      if (params.field === fieldRef.birthday) {
        return DateTime.fromMillis(params.value).toFormat("D");
        //return value;
      } else {
        return params.value;
      }
    };

    const getCellValue = (params: GridValueGetterParams) => {
      if (params.field === fieldRef.homeroom) {
        const currentHomeroom = homerooms.find((homeroom) => homeroom.id === params.value);
        if (currentHomeroom) {
          return currentHomeroom.name;
        } else {
          return "No Homeroom Selected";
        }
      } else if (params.field === fieldRef.birthday) {
        const dt = DateTime.fromFormat(params.value, "D").toMillis();
        return dt;
        //return value;
      } else {
        return params.value;
      }
    };
    const columnArray: ColumnInterface[] = [];
    sectionsAndFields.forEach((section) => {
      section.fields.forEach((field) => {
        if (filteredFields.includes(field.id)) {
          if (field.id !== "additionalContacts") {
            let type = "string";
            if (field.id === fieldRef.birthday) {
              type = "number";
            }
            columnArray.push({
              field: field.id,
              headerName: field.name,
              minWidth: 150,
              headerClassName: "tableHeader",
              cellClassName: "hoverPointer",
              valueGetter: getCellValue,
              valueFormatter: getCellFormat,
              type: type,
            });
          }
        }
      });
    });
    setColumns(columnArray);
  }, [filteredFields, sectionsAndFields, homerooms]);

  useEffect(() => {
    if (!fields) return;
    const tempArray: string[] = [];
    fields.forEach((f) => {
      if (f.tableDefault) {
        tempArray.push(f.id);
      }
    });
    setFilteredFields(tempArray);
  }, [fields]);

  return (
    <>
      <Box sx={{ pt: 2 }}>
        <Typography variant="h4">Student Table</Typography>
      </Box>
      <FiltersAccordion
        filteredFields={filteredFields}
        setFilteredFields={setFilteredFields}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        selectedEnrollStatus={selectedEnrollStatus}
        setSelectedEnrollStatus={setSelectedEnrollStatus}
      />
      <RosterTable
        rows={filteredStudents}
        columns={columns}
        setSelectedStudent={setSelectedStudent}
      />
      {selectedStudent && (
        <StudentDialogContainer
          selectedStudent={selectedStudent}
          setSelectedStudent={setSelectedStudent}
        />
      )}
    </>
  );
}
