import { Box, Paper, Typography, Button } from "@mui/material";
import React from "react";
import { useSetRecoilState } from "recoil";
import { VillageRecord } from "../../interfaces/interfaces";
import { villageFormAtom } from "../../recoil/villageAtoms";

type Props = {
  village: VillageRecord;
  setManageOpen: (value: boolean) => void;
  setDeleteOpen: (value: boolean) => void;
  setDeleteId: (value: string | null) => void;
};

function VillageCard({ village, setManageOpen, setDeleteOpen, setDeleteId }: Props) {
  const setVillageForm = useSetRecoilState(villageFormAtom);
  const handleFieldEdit = (village: VillageRecord) => {
    setVillageForm(village);
    setManageOpen(true);
  };

  const handleDeleteVillage = () => {
    setDeleteOpen(true);
    setDeleteId(village.id);
  };
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Paper sx={{ padding: 2, display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">{`Village Name: ${village.name}`}</Typography>
          <Box>
            <Button sx={{ mr: 2 }} variant="outlined" onClick={() => handleFieldEdit(village)}>
              Edit
            </Button>
            <Button variant="outlined" color="error" onClick={handleDeleteVillage}>
              Delete
            </Button>
          </Box>
        </Paper>
      </Box>
    </>
  );
}

export default VillageCard;
