import { useContext } from "react";
import { Button, Link } from "@mui/material";
import { AuthContext } from "../../providers/AuthProvider";

type Props = {
  setLoginType: (value: "staff" | "caregiver") => void;
};

function StaffLogin({ setLoginType }: Props) {
  const { signInWithGoogle } = useContext(AuthContext);

  return (
    <>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={signInWithGoogle}
        sx={{ mt: 2 }}
      >
        Sign In With Google
      </Button>
      <Link
        sx={{ cursor: "pointer", mt: 2 }}
        onClick={() => setLoginType("caregiver")}
        variant="body2"
      >
        {"Switch To Caregiver Login"}
      </Link>
    </>
  );
}

export default StaffLogin;
