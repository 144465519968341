import React, { useState, useEffect, SyntheticEvent } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Select,
  MenuItem,
  Grid,
  Button,
  SelectChangeEvent,
} from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { AdditionalContactInterface, StudentRecord } from "../../interfaces/interfaces";

const blankForm: AdditionalContactInterface = {
  firstName: "",
  lastName: "",
  phone: "",
  pickup: "Yes",
  relationship: "",
  email: "",
};

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  additionalContactIndex: number | null;
  selectedStudent: StudentRecord | null;
  setAdditionalContactIndex: (value: number | null) => void;
  setSelectedStudent: (value: StudentRecord | null) => void;
};

type FormState = EventTarget & {
  name: string;
  value: string;
};

export default function AdditionalContactDialog({
  open,
  setOpen,
  additionalContactIndex,
  selectedStudent,
  setAdditionalContactIndex,
  setSelectedStudent,
}: Props) {
  const [addContactForm, setAddContactForm] = useState(blankForm);
  const { sendRequest: updateDoc } = useUpdateDoc();

  useEffect(() => {
    if (!selectedStudent) return;
    if (additionalContactIndex === null || !selectedStudent.additionalContacts) return;
    const currentContact = { ...selectedStudent.additionalContacts[additionalContactIndex] };
    setAddContactForm({
      firstName: currentContact?.firstName ?? "",
      lastName: currentContact?.lastName ?? "",
      phone: currentContact?.phone ?? "",
      email: currentContact?.email ?? "",
      pickup: currentContact?.pickup ?? "",
      relationship: currentContact?.relationship ?? "",
    });
  }, [additionalContactIndex, selectedStudent]);

  const handleChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setAddContactForm({ ...addContactForm, [formState.name]: formState.value });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setAddContactForm({ ...addContactForm, [event.target.name]: event.target.value });
  };

  const handleClose = () => {
    setOpen(false);
    setAdditionalContactIndex(null);
    setAddContactForm(blankForm);
  };

  const handleSave = (event: SyntheticEvent) => {
    event.preventDefault();
    if (!selectedStudent) return;
    const tempArray = [...selectedStudent?.additionalContacts] || [];
    if (additionalContactIndex === null) {
      tempArray.push(addContactForm);
    } else {
      tempArray[additionalContactIndex] = addContactForm;
    }
    updateDoc({ col: "students", id: selectedStudent.id, data: { additionalContacts: tempArray } });
    setSelectedStudent({ ...selectedStudent, additionalContacts: tempArray });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSave}>
        <DialogTitle>Add Contact</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">First Name</Typography>
              <TextField
                onChange={handleChange}
                InputLabelProps={{ shrink: false }}
                required
                id="outlined-required"
                type="text"
                name="firstName"
                fullWidth
                value={addContactForm.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Last Name</Typography>
              <TextField
                onChange={handleChange}
                InputLabelProps={{ shrink: false }}
                required
                id="outlined-required"
                type="text"
                name="lastName"
                fullWidth
                value={addContactForm.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Phone</Typography>
              <TextField
                onChange={handleChange}
                variant="outlined"
                required
                name="phone"
                fullWidth
                value={addContactForm.phone}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Email</Typography>
              <TextField
                onChange={handleChange}
                InputLabelProps={{ shrink: false }}
                id="outlined-required"
                type="email"
                name="email"
                fullWidth
                value={addContactForm.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Allowed to Pick Child Up?</Typography>
              <Select
                id="childPickup"
                value={addContactForm?.pickup ? addContactForm?.pickup : ""}
                onChange={handleSelectChange}
                name="pickup"
                fullWidth
              >
                <MenuItem key="Yes" value="Yes">
                  Yes
                </MenuItem>
                <MenuItem key="No" value="No">
                  No
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Relationship to Child</Typography>
              <TextField
                onChange={handleChange}
                InputLabelProps={{ shrink: false }}
                required
                id="outlined-required"
                type="text"
                name="relationship"
                fullWidth
                value={addContactForm.relationship}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
