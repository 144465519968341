import { useState, useEffect, useCallback } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { gradesAtom } from "../../recoil/gradeAtoms";
import { villagesAtom } from "../../recoil/villageAtoms";
import { staffAtom } from "../../recoil/staffAtoms";

import { homeroomFormAtom } from "../../recoil/homeroomAtoms";
import { StaffRecord } from "../../interfaces/interfaces";
import { SelectChangeEvent } from "@mui/material";
import { TextField, Select, MenuItem, InputLabel, Autocomplete, FormControl } from "@mui/material";

function HomeroomDialogForm() {
  const grades = useRecoilValue(gradesAtom);
  const staff = useRecoilValue(staffAtom);
  const villages = useRecoilValue(villagesAtom);
  const [homeroomForm, setHomeroomForm] = useRecoilState(homeroomFormAtom);
  const [notTerminatedStaff, setNotTerminatedStaff] = useState<StaffRecord[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<StaffRecord[]>([]);

  useEffect(() => {
    if (!staff) return;
    const tempSelectedStaff = staff.filter(
      (staffMember) => homeroomForm.staff.includes(staffMember.id) && !staffMember.terminated
    );
    setSelectedStaff(tempSelectedStaff);
  }, [staff, homeroomForm]);

  useEffect(() => {
    if (!staff) return;
    const filteredStaff = staff.filter((s) => !s.terminated);
    setNotTerminatedStaff(filteredStaff);
  }, [staff]);

  const handleTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setHomeroomForm((pV) => ({ ...pV, [event.target.name]: event.target.value }));
    },
    [setHomeroomForm]
  );

  const handleGradeChange = useCallback(
    (event: SelectChangeEvent) => {
      setHomeroomForm((pV) => ({ ...pV, gradeId: event.target.value }));
    },
    [setHomeroomForm]
  );

  const handleVillageChange = useCallback(
    (event: SelectChangeEvent) => {
      setHomeroomForm((pV) => ({ ...pV, villageId: event.target.value }));
    },
    [setHomeroomForm]
  );

  const handleStaffSelect = useCallback(
    (event: any, values: StaffRecord[]) => {
      const currentSelectedStaff: string[] = [];
      values.forEach((s) => {
        currentSelectedStaff.push(s.id);
      });
      setHomeroomForm((pV) => ({ ...pV, staff: currentSelectedStaff }));
    },
    [setHomeroomForm]
  );

  return (
    <>
      <TextField
        autoFocus
        name="name"
        required
        label="Homeroom Name"
        fullWidth
        value={homeroomForm.name}
        onChange={handleTextChange}
        sx={{ mt: 2 }}
      />
      <FormControl required sx={{ marginTop: 2 }} fullWidth>
        <InputLabel id="grade-select-label">Select Grade</InputLabel>
        <Select
          value={homeroomForm.gradeId}
          fullWidth
          labelId="grade-select-label"
          onChange={handleGradeChange}
          label="Select Grade"
        >
          {grades &&
            grades.map((grade) => (
              <MenuItem value={grade.id} key={grade.id}>
                {grade.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl required sx={{ marginTop: 2 }} fullWidth>
        <InputLabel id="village-select-label">Select Village</InputLabel>
        <Select
          value={homeroomForm.villageId}
          fullWidth
          labelId="village-select-label"
          onChange={handleVillageChange}
          label="Select Village"
        >
          {villages &&
            villages.map((village) => (
              <MenuItem value={village.id} key={village.id}>
                {village.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {homeroomForm && (
        <Autocomplete
          multiple
          sx={{ mt: 2 }}
          options={notTerminatedStaff}
          getOptionLabel={(staff) => staff.firstName + " " + staff.lastName}
          onChange={handleStaffSelect}
          value={selectedStaff}
          filterSelectedOptions
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {`${option.firstName} ${option.lastName}`}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Assigned Staff"
              placeholder="Select Staff"
            />
          )}
        />
      )}
    </>
  );
}

export default HomeroomDialogForm;
