import { Select, MenuItem, SelectChangeEvent } from "@mui/material";

type Props = {
  value: string;
  setValue: (value: string) => void;
  options: string[];
};

export default function SelectEdit({ value, setValue, options }: Props) {
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };
  return (
    <Select fullWidth value={value ? value : "Please Select One"} onChange={handleChange}>
      {options &&
        options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
    </Select>
  );
}
