import { TextField, Typography } from "@mui/material";
import { SyntheticEvent } from "react";
import { FieldInterface, Student } from "../../interfaces/interfaces";

type Props = {
  field: FieldInterface;
  multiline: boolean;
  form: Student;
  setForm: (value: Student) => void;
};

type FormState = EventTarget & {
  name: string;
  value: string;
};

export default function ShortAnswer({ field, multiline, form, setForm }: Props) {
  const minRows = multiline ? 4 : 0;

  const handleChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setForm({ ...form, [field.id]: formState.value });
    localStorage.setItem("tgp-form", JSON.stringify(form))
  };

  return (
    <>
      <Typography variant="body2">{field.question}</Typography>
      <TextField
        onChange={handleChange}
        multiline={multiline}
        minRows={minRows}
        InputLabelProps={{ shrink: false }}
        required={field.required}
        id="outlined-required"
        type="text"
        fullWidth
        value={form[field.id]}
      />
    </>
  );
}
