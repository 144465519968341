import { GridToolbar, DataGridPro } from "@mui/x-data-grid-pro";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ClassSummaryColumns } from "../../libraries/columns";

type Row = {
  homeroomName: string;
  numberOfStudents: number;
  id: string;
  black: number;
  white: number;
  latinx: number;
  asian: number;
  indigenous: number;
  otherRace: number;
  male: number;
  female: number;
  otherGender: number;
};

type Props = {
  rows: Row[];
};

export default function ClassSummaryTable({ rows }: Props) {
  const window = useWindowDimensions();

  return (
    <>
      {rows && (
        <div style={{ height: window.height - 300, width: "100%" }}>
          <DataGridPro
            rows={rows}
            columns={ClassSummaryColumns}
            rowHeight={34}
            disableSelectionOnClick
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      )}
    </>
  );
}
