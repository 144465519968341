import { createContext, useState, useEffect, useCallback, useContext } from "react";
import {
  signInWithPopup,
  UserCredential,
  User as FirebaseUser,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  sendEmailVerification,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth, provider } from "../firebase";
import { validateLoginEmail } from "../libraries/functions";

interface AuthContextInterface {
  loading: boolean;
  currentAuthUser: FirebaseUser | null;
  logout: () => void;
  signInWithGoogle: () => void;
  signUserIn: ({ email, password }: { email: string; password: string }) => void;
  updateUserPassword: (newPassword: string) => void;
  resetPassword: (email: string) => void;
  resetMessage: string | null;
  loginError: string | null;
  createUser: ({ email, password }: { email: string; password: string }) => void;
}

const initialState = () => ({
  currentAuthUser: null,
  logout: () => {},
  loading: false,
  signInWithGoogle: () => {},
  signUserIn: ({ email, password }: { email: string; password: string }) => {},
  updateUserPassword: (newPassword: string) => {},
  resetPassword: (email: string) => {},
  resetMessage: "",
  loginError: "",
  createUser: ({ email, password }: { email: string; password: string }) => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthContext = createContext<AuthContextInterface>(initialState());
export { AuthContext };

type Props = {
  children: JSX.Element;
};

const AuthProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [currentAuthUser, setCurrentAuthUser] = useState<FirebaseUser | null>(null);
  const [loginError, setLoginError] = useState<string | null>(null);
  const [resetMessage, setResetMessage] = useState<string | null>(null);

  const signInWithGoogle = useCallback(() => {
    setLoading(true);
    signInWithPopup(auth, provider).then(async ({ user }: UserCredential) => {
      if (!user || !user.email) {
        auth.signOut();
        setLoading(false);
        return;
      }
      user.email.split("@")[1] === "thegatheringplacek12.org" || auth.signOut();
    });
  }, []);

  const logout = useCallback(async() => {
    setCurrentAuthUser(null);
    await auth.signOut();
    window.location.reload();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user: FirebaseUser | null) => {
      if (user) {
        setCurrentAuthUser(user);
      } else {
        setCurrentAuthUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (currentAuthUser) {
      setLoading(false);
    }
  }, [currentAuthUser]);

  const signUserIn = ({ email, password }: { email: string; password: string }) => {
    setLoginError(null);
    setResetMessage(null);
    const emailValidate = validateLoginEmail(email);
    if (!emailValidate) {
      setLoginError("Please Enter a Valid Email Address Above");
      return;
    }
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setCurrentAuthUser(userCredential.user);
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/wrong-password":
            setLoginError(
              'Incorrect password, please try again or using the link below, reset your password by clicking "Forgot Password?"'
            );
            break;
          case "auth/user-not-found":
            setLoginError(
              "We do not have a user with that email. Using the link below, click \"Don't have an account? Click Create Account Below"
            );
            break;
          default:
            setLoginError(`Something Went Wrong, Please Try Again Error Code ${error.code}`);
        }
        logout();
      });
  };

  const resetPassword = (email: string) => {
    setLoginError(null);
    const emailValidate = validateLoginEmail(email);
    if (!emailValidate) {
      setLoginError("Please Enter a Valid Email Address Above");
    }
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setResetMessage(
          `An email with instructions for how to reset your password was just sent to ${email}. Please follow those instructions to reset your password. `
        );
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          setLoginError("We Do Not Have a User With That Email");
        } else if (error.code === "auth/missing-email") {
          setLoginError(
            "Please enter your email in the email field below and then click Forgot Password"
          );
        } else {
          setLoginError("Something Went Wrong, Please Try Again");
        }
      });
  };

  const updateUserPassword = async (newPassword: string) => {
    const user = auth.currentUser;
    if (user?.email) {
      const credential = EmailAuthProvider.credential(user.email, "TempPassword");
      // Now you can use that to reauthenticate
      reauthenticateWithCredential(user, credential);
      updatePassword(user, newPassword);
    }
  };

  const createUser = ({ email, password }: { email: string; password: string }) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        sendEmailVerification(user).then(() => {
          // Email verification sent!
          // ...
        });

        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        // ..
      });
  };

  const value = {
    currentAuthUser,
    signInWithGoogle,
    logout,
    loading,
    signUserIn,
    updateUserPassword,
    resetPassword,
    resetMessage,
    loginError,
    createUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
