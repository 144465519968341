import { SyntheticEvent } from "react";
import { TextField } from "@mui/material";

type Props = {
  value: string;
  setValue: (value: string) => void;
};

type FormState = EventTarget & {
  name: string;
  value: string;
};

export default function PhoneEdit({ value, setValue }: Props) {
  const handleChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setValue(formState.value);
  };

  return (
    <>
      <TextField
        autoFocus
        id="edit"
        type="text"
        fullWidth
        value={value}
        variant="standard"
        onChange={handleChange}
      />
    </>
  );
}
