import { db } from "../firebase";
import { Button, Box, Typography } from "@mui/material";
import { getDocs, collection, addDoc, doc, updateDoc, getDoc } from "firebase/firestore";
import { fieldRef } from "../libraries/objects";
import { Caregiver, CaregiverRecord } from "../interfaces/interfaces";

const AddAdditionalContactsFromStudents = () => {
  const addCaregiversFromStudents = async () => {
    const caregiverArray: Caregiver[] = [];
    const studentCollectionRef = collection(db, "students");
    const studentSnapshot = await getDocs(studentCollectionRef);
    const cgCollectionRef = collection(db, "caregivers");
    const cgSnapshot = await getDocs(cgCollectionRef);
    const existingCaregivers: CaregiverRecord[] = [];
    cgSnapshot.forEach((caregiverRecord) => {
      existingCaregivers.push({
        id: caregiverRecord.id,
        studentIds: [caregiverRecord.data().studentIds],
        firstName: caregiverRecord.data().firstName || "",
        lastName: caregiverRecord.data().lastName || "",
        relationship: caregiverRecord.data().relationship || "",
        pickup: caregiverRecord.data().pickup,
        email: caregiverRecord.data().email || "",
        streetAddress: caregiverRecord.data().streetAddress || "",
        apartmentNumber: caregiverRecord.data().apartmentNumber || "",
        primaryPhone: caregiverRecord.data().primaryPhone || "",
        secondaryPhone: caregiverRecord.data().secondaryPhone || "",
        createdAt: caregiverRecord.data().createdAt,
        lastUpdated: caregiverRecord.data().lastUpdated,
      });
    });
    studentSnapshot.forEach((record) => {
      const recordData = record.data();
      if (recordData.additionalContacts) {
        recordData.additionalContacts.forEach((additionalContact: any) => {
          const tempObj = {
            studentIds: [record.id],
            firstName: additionalContact.firstName ? additionalContact.firstName.trim() : "",
            lastName: additionalContact.lastName ? additionalContact.lastName.trim() : "",
            relationship: additionalContact.relationship
              ? additionalContact.relationship.trim()
              : "",
            pickup: additionalContact.pickup === "Yes" ? true : false,
            email: additionalContact.email ? additionalContact.email.trim().toLowerCase() : "",
            streetAddress: "",
            apartmentNumber: "",
            primaryPhone: additionalContact.phone ? additionalContact.phone.trim() : "",
          };
          const existingCaregiverIndex = caregiverArray.findIndex(
            (caregiver) =>
              caregiver.email?.toLowerCase() === tempObj.email.toLowerCase() ||
              caregiver.primaryPhone.replace(/\D/g, "") === tempObj.primaryPhone.replace(/\D/g, "")
          );
          if (existingCaregiverIndex > -1) {
            caregiverArray[existingCaregiverIndex].studentIds.push(record.id);
          } else {
            caregiverArray.push(tempObj);
          }
        });
      }
    });
    caregiverArray.forEach(async (caregiver) => {
      const existingCaregiverRecord = existingCaregivers.findIndex((existingCaregiver) => {
        return (
          caregiver.email === existingCaregiver.email ||
          caregiver.primaryPhone.replace(/\D/g, "") ===
            existingCaregiver.primaryPhone.replace(/\D/g, "")
        );
      });
      if (existingCaregiverRecord === -1) {
        const newCollectionRef = collection(db, "caregivers");
        const newCaregiverId = await addDoc(newCollectionRef, caregiver);
        caregiver.studentIds.forEach(async (studentId) => {
          const studentDocRef = doc(db, "students", studentId);
          const studentSnapshot = await getDoc(studentDocRef);
          const currentStudent = studentSnapshot.data();
          if (currentStudent) {
            const additionalCaregiversArray = currentStudent?.additionalCaregivers ?? [];
            additionalCaregiversArray.push(newCaregiverId);
            updateDoc(studentDocRef, { additionalCaregivers: additionalCaregiversArray });
          }
        });
      }
    });
  };

  return (
    <>
      <Box sx={{ margin: 4 }}>
        <Typography variant="h5">
          Click to add all additional contacts in student records to a caregiver collection
        </Typography>
        <Button sx={{ mr: 2 }} onClick={addCaregiversFromStudents} variant="contained">
          Add Additional Contacts From Students
        </Button>
      </Box>
    </>
  );
};

export default AddAdditionalContactsFromStudents;
