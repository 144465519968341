import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { Card, CardContent, CardActions, Fab, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DateTime } from "luxon";
import { loggedInStaffAtom, staffAtom } from "../../recoil/staffAtoms";
import { CommentInterface, StaffRecord } from "../../interfaces/interfaces";

type Props = {
  comment: CommentInterface;
  index: number | null;
  setIndex: (value: number | null) => void;
  setEditOpen: (value: boolean) => void;
  setDeleteOpen: (value: boolean) => void;
};

export default function Comment({ comment, index, setIndex, setEditOpen, setDeleteOpen }: Props) {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const staff = useRecoilValue(staffAtom);
  const [authorStaff, setAuthorStaff] = useState<StaffRecord | null>(null);

  useEffect(() => {
    if (!staff) return;
    const selectedStaff = staff.find((staffMember) => staffMember.id === comment.authorEmail);
    if (selectedStaff) {
      setAuthorStaff(selectedStaff);
    }
  }, [staff, comment]);

  const handleEditOpen = () => {
    setIndex(index);
    setEditOpen(true);
  };

  const handleDeleteOpen = () => {
    setIndex(index);
    setDeleteOpen(true);
  };

  const dt = comment.timestamp ? DateTime.fromMillis(comment.timestamp.toMillis()) : null;

  return (
    <>
      <Card sx={{ marginBottom: 2 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {(authorStaff && authorStaff.firstName) || comment.authorEmail}
          </Typography>
          <Typography gutterBottom variant="body2" component="div" color="text.primary">
            {dt
              ? dt.toLocaleString({
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })
              : "No Date"}{" "}
          </Typography>
          <Typography variant="body1" color="text.primary">
            {comment.text}
          </Typography>
        </CardContent>
        {loggedInStaff && loggedInStaff.id === comment.authorEmail && (
          <CardActions sx={{ display: "block", textAlign: "right" }}>
            <Fab size="small" color="secondary" aria-label="edit" onClick={handleEditOpen}>
              <EditIcon />
            </Fab>
            <Fab
              size="small"
              //TODO: Need to get color working
              color="error"
              aria-label="delete"
              onClick={handleDeleteOpen}
            >
              <DeleteIcon />
            </Fab>
          </CardActions>
        )}
      </Card>
    </>
  );
}
