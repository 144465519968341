import EditIcon from "@mui/icons-material/Edit";
import { FieldInterface } from "../../interfaces/interfaces";

type Props = {
  setSelectedField: (value: FieldInterface) => void;
  field: FieldInterface;
};

export default function CustomEditIcon({ setSelectedField, field }: Props) {
  const handleEditFieldOpen = () => {
    setSelectedField(field);
  };
  return <EditIcon onClick={handleEditFieldOpen} sx={{ ml: 1, fontSize: 22, cursor: "pointer" }} />;
}
