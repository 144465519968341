import { Container, Backdrop, CircularProgress } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import HomeroomContainer from "../components/Homerooms/HomeroomContainer";
import { villagesAtom } from "../recoil/villageAtoms";
import { homeroomsAtom } from "../recoil/homeroomAtoms";
import { gradesAtom } from "../recoil/gradeAtoms";
import { useRecoilValue } from "recoil";

const HomeroomsPage = () => {
  const grades = useRecoilValue(gradesAtom);
  const villages = useRecoilValue(villagesAtom);
  const homerooms = useRecoilValue(homeroomsAtom);

  const loading = grades.length > 0 && homerooms.length > 0 && villages.length > 0;

  return (
    <>
      <Navbar />

      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: 8, pt: 2 }}>
        {!loading ? (
          <Backdrop open={!loading}>
            <CircularProgress />
          </Backdrop>
        ) : (
          <HomeroomContainer />
        )}
      </Container>
    </>
  );
};

export default HomeroomsPage;
