import { Box, List, ListItem, ListItemButton, ListItemText, Divider } from "@mui/material";
import { SettingsSections } from "../../libraries/objects";

type Props = {
  setActiveSettingSection: (newValue: string) => void;
};

export default function SettingsSideMenu({ setActiveSettingSection }: Props) {
  return (
    <Box sx={{ width: "100%" }}>
      <nav>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setActiveSettingSection(SettingsSections.FIELDS);
              }}
            >
              <ListItemText primary="Registration Fields" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setActiveSettingSection(SettingsSections.VILLAGES);
              }}
            >
              <ListItemText primary="Villages" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setActiveSettingSection(SettingsSections.GRADES);
              }}
            >
              <ListItemText primary="Grades" />
            </ListItemButton>
          </ListItem>
          <Divider />
        </List>
      </nav>
    </Box>
  );
}
