import { useState, useContext } from "react";
import { Button, Typography, TextField, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { AuthContext } from "../../providers/AuthProvider";

type Props = {
  open: boolean;
  setOpen: (prop: false) => void;
};

type CredentialProps = {
  email: string;
  password: string;
};

const blankCredentials: CredentialProps = { email: "", password: "" };

function CreateUser({ open, setOpen }: Props) {
  const [credentials, setCredentials] = useState<CredentialProps>(blankCredentials);
  const { createUser } = useContext(AuthContext);

  const handleClose = () => {
    setOpen(false);
    setCredentials(blankCredentials);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials((prevValue) => ({
      ...prevValue,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = () => {
    createUser(credentials);
  };
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Create Account</DialogTitle>
        <DialogContent>
          <Typography>Enter Your Information Below to Create an Account.</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            type="email"
            value={credentials.email}
            autoFocus
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            value={credentials.password}
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
          />
          <Button fullWidth onClick={handleSubmit} variant="contained" sx={{ mt: 3, mb: 2 }}>
            Create Account
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateUser;
