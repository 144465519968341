import {
  HomeroomRecord,
  GradeRecord,
  DepartmentsInterface,
  SectionInterface,
  FieldInterface,
  StaffRecord,
  ChangeLog,
  VillageRecord,
} from "../interfaces/interfaces";

export const parseHomeroomResponse = (response: HomeroomRecord[]): HomeroomRecord[] =>
  response.map((homeroom: HomeroomRecord) => ({
    id: homeroom?.id?.trim() ?? null,
    name: homeroom?.name ?? "",
    gradeId: homeroom?.gradeId?.trim() ?? "",
    villageId: homeroom?.villageId?.trim() ?? "",
    lastUpdated: homeroom?.lastUpdated ?? null,
    createdAt: homeroom?.createdAt ?? null,
    staff: homeroom?.staff ?? [],
  }));

export const parseGradesResponse = (response: GradeRecord[]): GradeRecord[] =>
  response.map((grade: GradeRecord) => ({
    id: grade?.id?.trim() ?? "",
    name: grade?.name?.trim() ?? "",
    order: grade?.order ?? 0,
    lastUpdated: grade?.lastUpdated ?? null,
    createdAt: grade?.createdAt ?? null,
  }));

export const parseDepartmentsResponse = (
  response: DepartmentsInterface[]
): DepartmentsInterface[] =>
  response.map((department: DepartmentsInterface) => ({
    id: department?.id?.trim() ?? "",
    name: department?.name?.trim() ?? "",
  }));

export const parseSectionResponse = (response: SectionInterface[]): SectionInterface[] =>
  response.map((section: SectionInterface) => ({
    id: section?.id?.trim() ?? "",
    name: section?.name?.trim() ?? "",
    order: section?.order ?? 0,
    timestamp: section?.timestamp ?? null,
    fields: [],
    showCaregiver: section?.showCaregiver ?? false,
  }));

export const parseFieldResponse = (response: FieldInterface[]): FieldInterface[] =>
  response.map((field: FieldInterface) => ({
    id: field?.id?.trim() ?? "",
    name: field?.name?.trim() ?? "",
    order: field?.order ?? 0,
    timestamp: field?.timestamp ?? null,
    columns: field?.columns ?? 12,
    inForm: field?.inForm ?? false,
    options: field?.options ?? [],
    question: field?.question?.trim() ?? "",
    required: field?.required ?? false,
    sectionId: field?.sectionId?.trim() ?? "",
    type: field?.type?.trim() ?? "",
    tableDefault: field?.tableDefault ?? false,
    caregiverCanEdit: field?.caregiverCanEdit ?? false,
  }));

export const parseStaffResponse = (response: StaffRecord[]): StaffRecord[] =>
  response.map((staff: StaffRecord) => ({
    id: staff?.id?.trim() ?? "",
    department: staff?.department?.trim() ?? "",
    email: staff?.email?.trim() ?? "",
    firstName: staff?.firstName?.trim() ?? "",
    homerooms: staff?.homerooms ?? [],
    jobTitle: staff?.jobTitle?.trim() ?? "",
    lastLoggedIn: staff?.lastLoggedIn ?? null,
    lastName: staff?.lastName?.trim() ?? "",
    nonExempt: staff?.nonExempt ?? false,
    permission: staff?.permission ?? "",
    ptoPolicy: staff?.ptoPolicy?.trim() ?? "",
    sitePermissions: staff?.sitePermissions ?? [],
    startDate: staff?.startDate?.trim() ?? "",
    termDate: staff?.termDate?.trim() ?? "",
    terminated: staff?.terminated ?? "",
    createdAt: staff?.createdAt ?? null,
    lastUpdated: staff?.lastUpdated ?? null,
    uid: staff?.uid ?? "",
  }));

export const parseChangeLogsResponse = (response: ChangeLog[]): ChangeLog[] =>
  response.map((changeLog: ChangeLog) => ({
    id: changeLog?.id?.trim() ?? "",
    recordId: changeLog?.recordId?.trim() ?? "",
    message: changeLog?.message.trim() ?? 0,
    createdAt: changeLog?.createdAt ?? null,
    authorId: changeLog?.authorId ?? "",
  }));

export const parseVillagesResponse = (response: VillageRecord[]): VillageRecord[] =>
  response.map((village: VillageRecord) => ({
    id: village?.id?.trim() ?? "",
    name: village?.name?.trim() ?? "",
    order: village?.order ?? 0,
    createdAt: village?.createdAt ?? null,
    lastUpdated: village?.lastUpdated ?? null,
  }));
