import { atom } from "recoil";
import { VillageRecord, Village } from "../interfaces/interfaces";
import { BLANK_VILLAGE_FORM } from "../libraries/blankForms";

export const villagesAtom = atom<VillageRecord[]>({
  key: "villages",
  default: [],
});

export const villageFormAtom = atom<Village | VillageRecord>({
  key: "villageForm",
  default: BLANK_VILLAGE_FORM,
});

export const villagesResetAtom = atom({
  key: "villagesReset",
  default: false,
});
