import { useState, useContext } from "react";
import { Button, Typography, Grid, Link, TextField, Alert } from "@mui/material";
import { AuthContext } from "../../providers/AuthProvider";
import CreateUser from "./CreateUser";

type Props = {
  setLoginType: (value: "staff" | "caregiver") => void;
};

function CaregiverLogin({ setLoginType }: Props) {
  const [loginCredentials, setLoginCredentials] = useState({ email: "", password: "" });
  const [createOpen, setCreateOpen] = useState(false);
  const { signUserIn, resetPassword, loginError, resetMessage } = useContext(AuthContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginCredentials((prevValue) => ({ ...prevValue, [event.target.name]: event.target.value }));
  };

  const handleSubmit = () => {
    signUserIn(loginCredentials);
  };

  const handleResetPassword = () => {
    resetPassword(loginCredentials.email);
  };

  return (
    <>
      <Typography sx={{ mt: 2 }} variant="h6">
        Caregiver Sign In
      </Typography>
      {loginError && (
        <Alert sx={{ mt: 2, mb: 2 }} severity="error">
          {loginError}
        </Alert>
      )}
      {resetMessage && (
        <Alert sx={{ mt: 2, mb: 2 }} severity="success">
          {resetMessage}
        </Alert>
      )}
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        type="email"
        value={loginCredentials.email}
        autoFocus
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        value={loginCredentials.password}
        id="password"
        autoComplete="current-password"
        onChange={handleChange}
      />
      <Button fullWidth onClick={handleSubmit} variant="contained" sx={{ mt: 3, mb: 2 }}>
        Sign In
      </Button>
      <Grid container>
        <Grid item xs={12}>
          <Link sx={{ cursor: "pointer" }} onClick={handleResetPassword} variant="body2">
            Forgot Password?
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link sx={{ cursor: "pointer" }} onClick={() => setCreateOpen(true)} variant="body2">
            {"Create Caregiver Account"}
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link sx={{ cursor: "pointer" }} onClick={() => setLoginType("staff")} variant="body2">
            {"Switch To Staff Login"}
          </Link>
        </Grid>
      </Grid>
      <CreateUser open={createOpen} setOpen={setCreateOpen} />
    </>
  );
}

export default CaregiverLogin;
