import { useCallback } from "react";
import { Card, Fab, CardContent, CardActions, Typography } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { PERMISSIONS } from "../../libraries/objects";
import { gradesAtom } from "../../recoil/gradeAtoms";
import { villagesAtom } from "../../recoil/villageAtoms";
import { loggedInStaffAtom } from "../../recoil/staffAtoms";
import { homeroomFormAtom } from "../../recoil/homeroomAtoms";
import { HomeroomRecord } from "../../interfaces/interfaces";

type Props = {
  homeroom: HomeroomRecord;
  setManageOpen: (prop: boolean) => void;
  setDeleteId: (prop: string) => void;
  setDeleteOpen: (prop: boolean) => void;
};

const HomeroomCard = ({ homeroom, setManageOpen, setDeleteId, setDeleteOpen }: Props) => {
  const grades = useRecoilValue(gradesAtom);
  const villages = useRecoilValue(villagesAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const setHomeroomForm = useSetRecoilState(homeroomFormAtom);
  const currentGrade = grades.find((grade) => grade.id === homeroom.gradeId);
  const currentVillage = villages.find((village) => village.id === homeroom.villageId);

  const handleDialogOpen = useCallback(() => {
    setManageOpen(true);
    setHomeroomForm({ ...homeroom });
  }, [homeroom, setHomeroomForm, setManageOpen]);

  const handleDeleteOpen = useCallback(() => {
    setDeleteOpen(true);
    setDeleteId(homeroom.id);
  }, [setDeleteId, setDeleteOpen, homeroom]);

  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography variant="h6">{homeroom.name}</Typography>
          <Typography component="span">
            <b>Grade: </b>
          </Typography>
          <Typography component="span">
            {currentGrade ? currentGrade.name : "No Selected Grade"}
          </Typography>
          <div></div>
          <Typography component="span">
            <b>Village: </b>
          </Typography>
          <Typography component="span" sx={{ mb: 1.5 }}>
            {currentVillage ? currentVillage.name : "No Selected Village"}
          </Typography>
        </CardContent>
        {loggedInStaff?.sitePermissions?.includes(PERMISSIONS.EDIT_HOMEROOMS) && (
          <CardActions sx={{ display: "block", textAlign: "right" }}>
            <Fab size="small" color="secondary" onClick={handleDialogOpen}>
              <EditIcon />
            </Fab>
            <Fab size="small" color="error" onClick={handleDeleteOpen}>
              <DeleteIcon />
            </Fab>
          </CardActions>
        )}
      </Card>
    </>
  );
};

export default HomeroomCard;
