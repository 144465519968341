import React, { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { FormControlLabel, Box, Switch, Typography } from "@mui/material";
import { PERMISSIONS_ARRAY } from "../../libraries/objects";
import { StaffFormAtom } from "../../recoil/staffAtoms";

export default function SetSitePermissions() {
  const [staffForm, setStaffForm] = useRecoilState(StaffFormAtom);
  const [sitePermissions, setSitePermissions] = useState(staffForm.sitePermissions || []);

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      let currentPermissions = [...sitePermissions];
      if (event.target.checked && !currentPermissions.includes(event.target.name)) {
        currentPermissions.push(event.target.name);
      } else if (!event.target.checked && currentPermissions.includes(event.target.name)) {
        currentPermissions = currentPermissions.filter(
          (permission) => permission !== event.target.name
        );
      }
      setStaffForm((pV) => ({ ...pV, sitePermissions: currentPermissions }));
      setSitePermissions(currentPermissions);
    },
    [sitePermissions, setStaffForm]
  );

  return (
    <>
      <Typography sx={{ mt: 2 }} variant="body2">
        Select Site Permissions
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {PERMISSIONS_ARRAY.map((permission) => (
          <div key={permission.key}>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleChange}
                  name={permission.key}
                  checked={sitePermissions?.includes(permission.key) || false}
                />
              }
              label={permission.value}
            />
          </div>
        ))}
      </Box>
    </>
  );
}
