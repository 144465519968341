import Date from "./Date";
import ShortAnswer from "./ShortAnswer";
import Dropdown from "./Dropdown";
import AdditionalContactsContainer from "./AdditionalContactsContainer";
import Grid from "@mui/material/Grid";
import { FieldInterface, Student } from "../../interfaces/interfaces";

type Props = {
  field: FieldInterface;
  form: Student;
  setForm: (value: Student) => void;
};

export default function Question({ field, form, setForm }: Props) {
  if (!field.inForm) return null;
  switch (field.type) {
    case "text":
      return (
        <Grid item xs={12} sm={field.columns}>
          <ShortAnswer field={field} multiline={false} form={form} setForm={setForm} />
        </Grid>
      );
    case "email":
      return (
        <Grid item xs={12} sm={field.columns}>
          <ShortAnswer field={field} multiline={false} form={form} setForm={setForm} />
        </Grid>
      );
    case "textarea":
      return (
        <Grid item xs={12} sm={field.columns}>
          <ShortAnswer field={field} multiline={true} form={form} setForm={setForm} />
        </Grid>
      );
    case "select":
      return (
        <Grid item xs={12} sm={field.columns}>
          <Dropdown field={field} form={form} setForm={setForm} />
        </Grid>
      );
    case "date":
      return (
        <Grid item xs={12} sm={field.columns}>
          <Date field={field} form={form} setForm={setForm} />
        </Grid>
      );
    case "tel":
      return (
        <Grid item xs={12} sm={field.columns}>
          <ShortAnswer field={field} multiline={true} form={form} setForm={setForm} />
        </Grid>
      );
    case "additionalContactsButton":
      return (
        <Grid item xs={12} sm={field.columns}>
          <AdditionalContactsContainer form={form} setForm={setForm} />
        </Grid>
      );

    default:
      return (
        <Grid item sm={field.columns}>
          <ShortAnswer field={field} form={form} setForm={setForm} multiline={false} />
        </Grid>
      );
  }
}
