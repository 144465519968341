export const updateDragArray = ({ dropResult, arr }: any) => {
  console.log(dropResult);
  if (dropResult.removedIndex === null && dropResult.addedIndex === null) {
    return;
  }
  if (dropResult.removedIndex === null && dropResult.addedIndex === null) {
    return;
  }
  const result = [...arr];
  let itemToAdd = dropResult.payload;

  if (dropResult.removedIndex !== null) {
    itemToAdd = result.splice(dropResult.removedIndex, 1)[0];
  }

  if (dropResult.addedIndex !== null) {
    result.splice(dropResult.addedIndex, 0, itemToAdd);
  }
  return result;
};

export const validateLoginEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const compact = (arr: any[]) => {
  return arr.filter((val) => val);
};
