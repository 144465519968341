import { Box, Button } from "@mui/material";
import { AdditionalContactInterface, Student } from "../../interfaces/interfaces";
import AdditionalContactsEntry from "./AdditionalContactsEntry";

type Props = {
  form: Student;
  setForm: (value: Student) => void;
};

export default function AdditionalContactsContainer({ form, setForm }: Props) {
  const addContactOnChange = ({
    index,
    field,
    value,
  }: {
    index: number;
    field: "firstName" | "lastName" | "email" | "phone" | "relationship" | "pickup";
    value: string;
  }) => {
    const additionalContacts = form.additionalContacts;
    additionalContacts[index][field] = value;
    setForm({
      ...form,
      additionalContacts: additionalContacts,
    });
  };

  const addNewContact = () => {
    const contacts = form["additionalContacts"];
    contacts.push({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      relationship: "",
      pickup: "No",
    });
    setForm({ ...form, additionalContacts: contacts });
  };

  const removeAddContact = (index: number) => {
    const tempArray: AdditionalContactInterface[] = [];
    form.additionalContacts.forEach((contact, i) => {
      if (i !== index) {
        tempArray.push(contact);
      }
    });
    setForm({
      ...form,
      additionalContacts: tempArray,
    });
  };

  return (
    <>
      {form &&
        form["additionalContacts"].map((item, index) => (
          <AdditionalContactsEntry
            item={item}
            key={index}
            index={index}
            removeAddContact={removeAddContact}
            addContactOnChange={addContactOnChange}
            form={form}
          />
        ))}
      <Box sx={{ display: "block", textAlign: "center" }}>
        <Button size="large" sx={{ marginTop: 2 }} variant="outlined" onClick={addNewContact}>
          Add Additional Contact
        </Button>
      </Box>
    </>
  );
}
