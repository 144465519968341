import { useCallback, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loggedInStaffAtom } from "../../recoil/staffAtoms";
import { Grid, Fab, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { homeroomsAtom, homeroomsResetAtom } from "../../recoil/homeroomAtoms";
import { PERMISSIONS } from "../../libraries/objects";
import DeleteDialog from "../shared/DeleteDialog";
import HomeroomDialog from "./HomeroomDialog";
import HomeroomCard from "./HomeroomCard";

export default function HomeroomContainer() {
  const [manageOpen, setManageOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const homerooms = useRecoilValue(homeroomsAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const setHomeroomsReset = useSetRecoilState(homeroomsResetAtom);

  const handleNewHomeroom = useCallback(() => {
    setManageOpen(true);
  }, [setManageOpen]);

  return (
    <>
      <Typography variant="h2" sx={{ mt: 1, mb: 1 }}>
        Homerooms
      </Typography>
      <Grid container spacing={3}>
        {homerooms &&
          homerooms.map((homeroom) => (
            <Grid key={homeroom.id} item xs={12} sm={4}>
              <HomeroomCard
                homeroom={homeroom}
                setManageOpen={setManageOpen}
                setDeleteId={setDeleteId}
                setDeleteOpen={setDeleteOpen}
              ></HomeroomCard>
            </Grid>
          ))}
      </Grid>
      {loggedInStaff?.sitePermissions.includes(PERMISSIONS.EDIT_HOMEROOMS) && (
        <Fab
          sx={{ position: "fixed", bottom: "5vh", right: "5vw" }}
          color="primary"
          aria-label="add"
          onClick={handleNewHomeroom}
        >
          <AddIcon />
        </Fab>
      )}
      <HomeroomDialog open={manageOpen} setOpen={setManageOpen} />
      {deleteId && (
        <DeleteDialog
          open={deleteOpen}
          setOpen={setDeleteOpen}
          id={deleteId}
          collection="homerooms"
          message="Are you sure you want to delete this homeroom, this can not be undone."
          setReset={setHomeroomsReset}
        />
      )}
    </>
  );
}
