import {
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Box,
  SelectChangeEvent,
} from "@mui/material";
import SelectOption from "./SelectOption";
import { COLUMN_OPTIONS, TYPE_OPTIONS } from "../../libraries/objects";
import { FieldInterface, SectionInterface } from "../../interfaces/interfaces";
import { SyntheticEvent } from "react";

type Props = {
  fieldForm: FieldInterface;
  handleSelectChange: (event: SelectChangeEvent) => void;
  handleTextChange: (event: SyntheticEvent) => void;
  handleSwitchChange: (event: SyntheticEvent) => void;
  sections: SectionInterface[];
  setFieldForm: (value: FieldInterface | ((prevVar: FieldInterface) => FieldInterface)) => void;
};

export default function FieldsForm({
  fieldForm,
  handleSelectChange,
  sections,
  handleTextChange,
  setFieldForm,
  handleSwitchChange,
}: Props) {
  const selectOptions = fieldForm?.options ? fieldForm.options : ["Option"];
  return (
    <>
      <Typography sx={{ mb: 1 }}>Name:</Typography>
      <TextField
        fullWidth
        id="name"
        name="name"
        value={fieldForm.name}
        onChange={handleTextChange}
      />
      <Typography sx={{ mb: 1 }}>Section Field Belongs To:</Typography>
      <Select
        id="sectionId"
        name="sectionId"
        type="select"
        value={fieldForm.sectionId}
        fullWidth
        onChange={handleSelectChange}
      >
        {sections &&
          sections.map((section) => (
            <MenuItem key={section.id} value={section.id}>
              {section.name}
            </MenuItem>
          ))}
      </Select>
      <Typography sx={{ mb: 1 }}>Format:</Typography>

      <Select
        id="type"
        name="type"
        type="select"
        value={fieldForm.type}
        fullWidth
        onChange={handleSelectChange}
      >
        {TYPE_OPTIONS.map((option) => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {fieldForm.type === "select" &&
        selectOptions.map((option, index) => (
          <SelectOption
            option={option}
            index={index}
            setFieldForm={setFieldForm}
            key={"option" + index}
          />
        ))}
      <Box>
        <FormControlLabel
          control={
            <Switch
              name="tableDefault"
              checked={fieldForm.tableDefault}
              onChange={handleSwitchChange}
            />
          }
          label="Table Default"
        />
      </Box>
      <Box>
        <FormControlLabel
          control={
            <Switch
              name="caregiverCanEdit"
              checked={fieldForm.caregiverCanEdit}
              onChange={handleSwitchChange}
            />
          }
          label="Caregiver Can Edit"
        />
      </Box>
      <FormControlLabel
        control={<Switch name="inForm" checked={fieldForm.inForm} onChange={handleSwitchChange} />}
        label="In Registration Form"
      />
      {fieldForm.inForm && (
        <>
          <div>
            <FormControlLabel
              control={
                <Switch
                  name="required"
                  checked={fieldForm.required}
                  onChange={handleSwitchChange}
                />
              }
              label="Required"
            />
          </div>
          <Typography sx={{ mb: 1 }}>Question In Form:</Typography>
          <TextField
            fullWidth
            id="question"
            name="question"
            value={fieldForm.question}
            onChange={handleTextChange}
          />
          <Typography sx={{ mb: 1 }}>Width In Form:</Typography>
          <Select
            id="columns"
            name="columns"
            type="select"
            value={String(fieldForm.columns)}
            fullWidth
            onChange={handleSelectChange}
          >
            {COLUMN_OPTIONS.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </>
  );
}
