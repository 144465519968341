import { useState, useEffect, SyntheticEvent } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { departmentsAtom } from "../../recoil/atoms";
import { StaffFormAtom } from "../../recoil/staffAtoms";
import { homeroomsAtom } from "../../recoil/homeroomAtoms";
import {
  TextField,
  Typography,
  Select,
  MenuItem,
  Autocomplete,
  SelectChangeEvent,
  Grid,
} from "@mui/material";
import StaffDatePicker from "./StaffDatePicker";
import SetSitePermissions from "./SetSitePermissions";
import { HomeroomRecord } from "../../interfaces/interfaces";
import PTOPolicySelect from "./PTOPolicySelect";
import StaffSwitches from "./StaffSwitches";

type FormState = EventTarget & {
  name: string;
  value: string;
};

export default function StaffForm() {
  const homerooms = useRecoilValue(homeroomsAtom);
  const departments = useRecoilValue(departmentsAtom);
  const [staffForm, setStaffForm] = useRecoilState(StaffFormAtom);
  const [selectedHomerooms, setSelectedHomerooms] = useState<HomeroomRecord[]>([]);

  useEffect(() => {
    if (!homerooms) return;
    const tempSelectedHomerooms = homerooms.filter((homeroom) =>
      staffForm.homerooms.includes(homeroom.id || "")
    );
    setSelectedHomerooms(tempSelectedHomerooms);
  }, [homerooms, staffForm]);

  const handleHomeroomSelect = (event: any, values: HomeroomRecord[]) => {
    const currentSelectedHomerooms: string[] = [];
    values.forEach((homeroom) => {
      if (!homeroom.id) return;
      currentSelectedHomerooms.push(homeroom.id);
    });
    setStaffForm((pV) => ({ ...pV, homerooms: currentSelectedHomerooms }));
  };

  const handleTextChange = async (e: SyntheticEvent) => {
    const formState = e.target as FormState;
    setStaffForm((pV) => ({ ...pV, [formState.name]: formState.value }));
  };

  const handleSelectChange = async (event: SelectChangeEvent) => {
    setStaffForm((pV) => ({ ...pV, [event.target.name]: event.target.value }));
  };

  const handleSwitchChange = async ({ field, checked }: { field: string; checked: boolean }) => {
    setStaffForm((pV) => ({ ...pV, [field]: checked }));
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ mt: 2 }} variant="body2">
            First Name
          </Typography>
          <TextField
            name="firstName"
            fullWidth
            required
            value={staffForm.firstName}
            variant="outlined"
            onChange={handleTextChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ mt: 2 }} variant="body2">
            Last Name
          </Typography>
          <TextField
            name="lastName"
            fullWidth
            required
            value={staffForm.lastName}
            variant="outlined"
            onChange={handleTextChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ mt: 2 }} variant="body2">
            Select Department
          </Typography>
          {departments && (
            <Select
              name="department"
              value={staffForm.department}
              variant="outlined"
              fullWidth
              onChange={handleSelectChange}
            >
              {departments.map((department) => (
                <MenuItem key={department.id} value={department.name}>
                  {department.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ mt: 2 }} variant="body2">
            Job Title
          </Typography>
          <TextField
            name="jobTitle"
            type="text"
            fullWidth
            value={staffForm.jobTitle}
            required
            variant="outlined"
            onChange={handleTextChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ mt: 2 }} variant="body2">
            Start Date
          </Typography>
          <StaffDatePicker field="startDate" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ mt: 2 }} variant="body2">
            Term Date
          </Typography>
          <StaffDatePicker field="termDate" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <PTOPolicySelect handleSelectChange={handleSelectChange} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography sx={{ mt: 2 }} variant="body2">
            Email
          </Typography>
          <TextField
            type="email"
            name="email"
            fullWidth
            value={staffForm.email}
            required
            variant="outlined"
            onChange={handleTextChange}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <StaffSwitches handleSwitchChange={handleSwitchChange} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography sx={{ mt: 2 }} variant="body2">
            Select Assigned Classes
          </Typography>
          {homerooms && (
            <Autocomplete
              multiple
              sx={{ mt: 2, mb: 2 }}
              options={homerooms}
              getOptionLabel={(homeroom) => homeroom.name}
              onChange={handleHomeroomSelect}
              value={selectedHomerooms}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Assigned Homerooms"
                  placeholder="Select Homerooms"
                />
              )}
            />
          )}
        </Grid>
      </Grid>
      <SetSitePermissions />
    </>
  );
}
