import React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { AdditionalContactInterface, Student } from "../../interfaces/interfaces";

type Props = {
  index: number;
  item: AdditionalContactInterface;
  removeAddContact: (value: number) => void;
  form: Student;
  addContactOnChange: (value: {
    field: "firstName" | "lastName" | "email" | "phone" | "relationship" | "pickup";
    index: number;
    value: string;
  }) => void;
};

export default function AdditionalContactsEntry({
  index,
  item,
  removeAddContact,
  form,
  addContactOnChange,
}: Props) {
  return (
    <Grid container spacing={2} sx={{ marginTop: 2 }}>
      <Grid item sm={12} sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6">Additional Contact {index + 1}</Typography>
        <Button
          onClick={() => removeAddContact(index)}
          color={"error"}
          sx={{ marginLeft: 1 }}
          variant="outlined"
        >
          Remove
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body2">First Name</Typography>
        <TextField
          onChange={(event) => {
            addContactOnChange({
              field: "firstName",
              index: index,
              value: event.target.value,
            });
          }}
          InputLabelProps={{ shrink: false }}
          required
          id="outlined-required"
          type="text"
          value={form.additionalContacts[index].firstName}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body2">Last Name</Typography>
        <TextField
          onChange={(event) => {
            addContactOnChange({
              field: "lastName",
              index: index,
              value: event.target.value,
            });
          }}
          InputLabelProps={{ shrink: false }}
          required
          id="outlined-required"
          type="text"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body2">Phone</Typography>
        <TextField
          onChange={(event) => {
            addContactOnChange({
              field: "phone",
              index: index,
              value: event.target.value,
            });
          }}
          InputLabelProps={{ shrink: false }}
          required
          id="outlined-required"
          type="text"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body2">Email</Typography>
        <TextField
          onChange={(event) => {
            addContactOnChange({
              field: "email",
              index: index,
              value: event.target.value,
            });
          }}
          InputLabelProps={{ shrink: false }}
          required
          id="outlined-required"
          type="email"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body2">Allowed to Pick Child Up?</Typography>
        <Select
          value={form.additionalContacts[index].pickup ? form.additionalContacts[index].pickup : ""}
          id="childPickup"
          onChange={(event) => {
            addContactOnChange({
              field: "pickup",
              index: index,
              value: event.target.value,
            });
          }}
          fullWidth
        >
          <MenuItem key="Yes" value="Yes">
            Yes
          </MenuItem>
          <MenuItem key="No" value="No">
            No
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body2">Relationship to Child</Typography>
        <TextField
          onChange={(event) => {
            addContactOnChange({
              field: "relationship",
              index: index,
              value: event.target.value,
            });
          }}
          InputLabelProps={{ shrink: false }}
          required
          id="outlined-required"
          type="text"
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
