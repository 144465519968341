import { Box, CircularProgress, Container } from "@mui/material";
import Section from "./Section";
import { FieldInterface, SectionInterface, DropResult } from "../../interfaces/interfaces";

type Props = {
  handleSectionEdit: (section: SectionInterface) => void;
  handleFieldEdit: (field: FieldInterface) => void;
  handleDrop: (dropResult: DropResult, index: number) => void;
  setDeleteFieldId: (value: boolean | string) => void;
  setDeleteSectionId: (value: boolean | string) => void;
  sectionsAndFields: SectionInterface[];
  loading: boolean;
};

export default function SectionsAndFields({
  sectionsAndFields,
  handleSectionEdit,
  handleFieldEdit,
  handleDrop,
  setDeleteFieldId,
  setDeleteSectionId,
  loading,
}: Props) {
  console.log(loading)
  return (
    <>
      {loading ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Container>
          {sectionsAndFields &&
            sectionsAndFields.map((section, index) => (
              <Section
                section={section}
                key={section.id}
                handleSectionEdit={handleSectionEdit}
                handleFieldEdit={handleFieldEdit}
                handleDrop={handleDrop}
                index={index}
                setDeleteFieldId={setDeleteFieldId}
                setDeleteSectionId={setDeleteSectionId}
              />
            ))}
        </Container>
      )}
    </>
  );
}
