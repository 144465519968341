import { FieldInterface, SectionInterface, Village, VillageRecord } from "../interfaces/interfaces";

export const PERMISSIONS = {
  EDIT_HOMEROOMS: "Edit Homerooms",
  SEE_TERMED_STAFF: "See Termed Staff",
  EDIT_STAFF: "Edit Staff",
  SEE_ADMIN_COMMENTS: "See Admin Comments",
  EDIT_STUDENTS: "Edit Students",
  SEE_ALL_ROSTERS: "See All Rosters",
};

export const PERMISSIONS_ARRAY = [
  { key: "EDIT_HOMEROOMS", value: PERMISSIONS.EDIT_HOMEROOMS },
  { key: "SEE_TERMED_STAFF", value: PERMISSIONS.SEE_TERMED_STAFF },
  { key: "EDIT_STAFF", value: PERMISSIONS.EDIT_STAFF },
  { key: "SEE_ADMIN_COMMENTS", value: PERMISSIONS.SEE_ADMIN_COMMENTS },
  { key: "EDIT_STUDENTS", value: PERMISSIONS.EDIT_STUDENTS },
  { key: "SEE_ALL_ROSTERS", value: PERMISSIONS.SEE_ALL_ROSTERS },
];

export const REGISTRATION_STATUSES = {
  activelyEnrolled: "Actively Enrolled",
  forNextYear: "For Next Year",
  pendingStart: "Pending Start",
  withdrawn: "Withdrawn",
  neverShowed: "Never Showed",
};

export enum SettingsSections {
  GRADES = "Grades",
  FIELDS = "Registration Fields",
  VILLAGES = "Villages",
}

export enum ROLLUP_MENU_SECTIONS {
  CLASS_SUMMARY = "Class Summary",
}

export enum COLLECTION {
  fields = "fields",
  grades = "grades",
  departments = "departments",
  sections = "sections",
  staff = "staff",
  students = "students",
  homerooms = "homerooms",
}

export const COLLECTIONS = Object.values(COLLECTION);

export const COLUMN_OPTIONS = [
  {
    label: "Full Width",
    value: 12,
  },
  {
    label: "Half Width",
    value: 6,
  },
];

export const TYPE_OPTIONS = [
  {
    label: "Single Row Textfield",
    value: "text",
  },
  {
    label: "Drop Down List",
    value: "select",
  },
  {
    label: "Multi-Line Textfield",
    value: "textarea",
  },
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Phone",
    value: "tel",
  },
];

export const blankSectionForm: SectionInterface = {
  name: "",
  order: 0,
  timestamp: null,
  id: "",
  fields: [],
  showCaregiver: false,
};

export const blankFieldForm: FieldInterface = {
  name: "",
  order: 0,
  sectionId: "",
  inForm: true,
  tableDefault: false,
  question: "",
  columns: 0,
  type: "",
  caregiverCanEdit: false,
  required: true,
  options: ["Option"],
  id: "",
  timestamp: null,
};
export const fieldRef = {
  allergies: "rgcmrhOxWiD103vvluxz",
  apartmentNumber: "4Bks34HR7GRaPW6PSbi9",
  birthday: "vJlnYHoh9ljJng7JvOsL",
  campusResidencyCode: "Osp64AADE8PkHqx64WFG",
  caregiverNotes: "N99N8aEoqHxPwvGdtvx9",
  childFirstName: "p3dWXJPLW7iRXZXlamtT",
  childLastName: "FFIFT0LhY8WiRTtSk99g",
  childMiddleName: "AWPlaAzrDEIH3HDRjCbs",
  childPreferredName: "VLOPmFcxoLavkQk0Kyxj",
  currentGradeLevel: "JKiRMM6uZW4kB9UTRMNn",
  dataGender: "zVT8uR1TO69xtlX8fV6C",
  dataRace: "jYxBLeifudfyx1grMhOj",
  enrollStatus: "xjTonHZLqWP39HXNoIpE",
  homeroom: "eYnS1V8KmNNEJnrOzPAy",
  lastYearHomeroom: "eEk6pSy4ub06OMPwm4Zr",
  medicalConditions: "JudSza5ygx3ke4bnfmGj",
  medicine: "pIss73gHmuPeGho7MX77",
  primaryCaregiverFirstName: "GdurzmGVDOLPzKAtMN7w",
  primaryCaregiverLastName: "pSzWSmSIRUT4KVwaXImm",
  primaryCaregiverRelationship: "T2dRpUUPJCzKpobELi8D",
  primaryEmail: "aWDFBzeYMiD0dYamkbvD",
  primaryLanguageInHome: "KKG2HDng8fi6LWZvXOOX",
  primaryLanguageSpoken: "9d1Tk2nG4tqiNbC6VGSO",
  primaryPhone: "tOnOi1PeQWqNElmkfiZH",
  reportedFoster: "L5FrU9bL1R8zPZTxiZef",
  reportedGender: "OApGPIHL6cFoJYcESsya",
  reportedRace: "IqVvZNjDBaygbHdCX29j",
  schoolSPEDClassifications: "83kcW2N8fuXnkmApC0KU",
  schoolSPEDReporting: "X4dYvMTwm0pIRzAOnv9Z",
  secondaryPhone: "k5yOToTIuvC3wWIIYqlt",
  SID: "Jm5HZPeP2fLhqSzvsFwt",
  startDate: "IcI2svH5WkMIGBY8x8LR",
  streetAddress: "0XFedtFxzUcz1Qwt9yOb",
  temporaryLiving: "dER5DSEcJEBIgTDudn6j",
  withdrawlDate: "A8PNOnv16NGxXknfutQo",
  withdrawlReason: "20qmmK0itgZIfZinJr5N",
  previousSchool: "YQFbmwyD4E95VO2JzQIt",
  reportedDyslexia: "oBaTOuffmQ2FcQ8Lj54t",
  registrationGradeLevel: "asvnojQZTTnAtIdlFWWr",
  existingSiblings: "S47xyOe9QmMFFXrtAa2o",
  reportedSPED: "cpTAlPjgCbQ4gWw3iOqb",
  newSiblings: "1cxB0QFgr3H9sbDoaRkN",
  schoolFRLStatus: "DlAUjKk0w0D0qXXqeKJb",
  school504Reporting: "QTs7fXVWPja9HHqZOhM8",
  schoolDyslexiaReporting: "hdiOm2iVWu7JanIlBGPf",
  schoolEmergingBilingualReporting: "w9J3mYoq5rlAdDx8WoiW",
  submittedOn: "3EepKdIjJgMKSkrLGzUH",
  mediaRelease: "l9MB5bIKNVgHizZuA8yJ",
};
