import { Typography, Grid } from "@mui/material";
import { FieldInterface, SectionInterface, StudentRecord } from "../../interfaces/interfaces";
import Field from "./Field";

type Props = {
  section: SectionInterface;
  selectedStudent: StudentRecord;
  setSelectedField: (value: FieldInterface | null) => void;
};

export default function Section({ section, selectedStudent, setSelectedField }: Props) {
  return (
    <>
      <Typography variant="h5">{`${section.name}: `}</Typography>
      <Grid container spacing={2}>
        {section.fields.map((field, index) => (
          <Grid item xs={12} sm={6} key={field.id}>
            <Field
              field={field}
              selectedStudent={selectedStudent}
              setSelectedField={setSelectedField}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
