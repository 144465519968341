import React, { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { studentsResetAtom } from "../../recoil/studentAtoms";
import { homeroomsAtom } from "../../recoil/homeroomAtoms";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import TextEdit from "./TextEdit";
import SelectEdit from "./SelectEdit";
import DateEdit from "./DateEdit";
import PhoneEdit from "./PhoneEdit";
import HomeroomEdit from "./HomeroomEdit";
import { FieldInterface, StudentRecord } from "../../interfaces/interfaces";

type Props = {
  selectedStudent: StudentRecord | null;
  selectedField: FieldInterface | null;
  setSelectedField: (value: FieldInterface | null) => void;
  setSelectedStudent: (value: StudentRecord | null) => void;
};

export default function FieldEditDialog({
  selectedStudent,
  selectedField,
  setSelectedField,
  setSelectedStudent,
}: Props) {
  const homerooms = useRecoilValue(homeroomsAtom);
  const setStudentsReset = useSetRecoilState(studentsResetAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const [value, setValue] = useState<string>(
    selectedStudent && selectedField && selectedField.id
      ? String(selectedStudent[selectedField.id])
      : ""
  );

  const handleClose = () => {
    setSelectedField(null);
  };
  const handleSave = async () => {
    if (!selectedField || !selectedField.id || !selectedStudent || !selectedStudent.id) return;
    const data = { [selectedField.id]: value };
    await updateDoc({ col: "students", id: selectedStudent.id, data: data });
    setSelectedStudent({ ...selectedStudent, [selectedField.id]: value });
    setStudentsReset((pV) => !pV);
    setSelectedField(null);
  };

  return (
    <>
      {selectedField && (
        <Dialog open={selectedField ? true : false} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>{selectedField.name}</DialogTitle>
          <DialogContent>
            {selectedField.type === "text" && (
              <TextEdit value={value} setValue={setValue} type="text" />
            )}
            {selectedField.type === "textarea" && (
              <TextEdit value={value} setValue={setValue} type="textarea" />
            )}
            {selectedField.type === "email" && (
              <TextEdit value={value} setValue={setValue} type="email" />
            )}
            {selectedField.type === "tel" && <PhoneEdit value={value} setValue={setValue} />}
            {selectedField.type === "date" && <DateEdit value={value} setValue={setValue} />}
            {selectedField.type === "select" && (
              <SelectEdit value={value} setValue={setValue} options={selectedField.options} />
            )}
            {selectedField.type === "homeroom" && (
              <HomeroomEdit value={value} setValue={setValue} options={homerooms} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
