import { Box, Grid } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { studentsAtom } from "../../recoil/studentAtoms";
import StudentCard from "./StudentCard";
import { AuthContext } from "../../providers/AuthProvider";
import { fieldRef } from "../../libraries/objects";
import { StudentRecord } from "../../interfaces/interfaces";
import StudentDialogContainer from "../StudentDialog/StudentDialogContainer";

function StudentCardsList() {
  const [filteredStudents, setFilteredStudents] = useState<StudentRecord[]>([]);
  const [selectedStudent, setSelectedStudent] = useState<StudentRecord | null>();
  const students = useRecoilValue(studentsAtom);
  const { currentAuthUser } = useContext(AuthContext);

  useEffect(() => {
    if (!students || !currentAuthUser) return;
    setFilteredStudents(
      students.filter((student) => student[fieldRef.primaryEmail] === currentAuthUser.email)
    );
  }, [students, currentAuthUser]);
  return (
    <>
      <Box>
        <Grid container spacing={2}>
          {filteredStudents &&
            filteredStudents.map((student) => (
              <Grid item xs={12} sm={6} md={4} key={student.id}>
                <StudentCard
                  student={student}
                  key={student.id}
                  setSelectedStudent={setSelectedStudent}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
      {selectedStudent && (
        <StudentDialogContainer
          selectedStudent={selectedStudent}
          setSelectedStudent={setSelectedStudent}
        />
      )}
    </>
  );
}

export default StudentCardsList;
