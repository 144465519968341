import { db } from "../firebase";
import { Button, Box, Typography } from "@mui/material";
import { getDocs, collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { fieldRef } from "../libraries/objects";
import { Caregiver, CaregiverRecord } from "../interfaces/interfaces";

const AddPrimaryCaregiversFromStudents = () => {
  const addCaregiversFromStudents = async () => {
    const caregiverArray: Caregiver[] = [];
    const studentCollectionRef = collection(db, "students");
    const studentSnapshot = await getDocs(studentCollectionRef);
    const cgCollectionRef = collection(db, "caregivers");
    const cgSnapshot = await getDocs(cgCollectionRef);
    const existingCaregivers: CaregiverRecord[] = [];
    cgSnapshot.forEach((caregiverRecord) => {
      existingCaregivers.push({
        id: caregiverRecord.id,
        studentIds: [caregiverRecord.data().studentIds],
        firstName: caregiverRecord.data().firstName || "",
        lastName: caregiverRecord.data().lastName || "",
        relationship: caregiverRecord.data().relationship || "",
        pickup: caregiverRecord.data().pickup,
        email: caregiverRecord.data().email || "",
        streetAddress: caregiverRecord.data().streetAddress || "",
        apartmentNumber: caregiverRecord.data().apartmentNumber || "",
        primaryPhone: caregiverRecord.data().primaryPhone || "",
        secondaryPhone: caregiverRecord.data().secondaryPhone || "",
        createdAt: caregiverRecord.data().createdAt,
        lastUpdated: caregiverRecord.data().lastUpdated,
      });
    });
    studentSnapshot.forEach((studentRecord) => {
      const studentRecordData = studentRecord.data();
      const tempObj = {
        studentIds: [studentRecord.id],
        firstName: studentRecordData[fieldRef.primaryCaregiverFirstName].trim() || "",
        lastName: studentRecordData[fieldRef.primaryCaregiverLastName].trim() || "",
        relationship: studentRecordData[fieldRef.primaryCaregiverRelationship].trim() || "",
        pickup: true,
        email: studentRecordData[fieldRef.primaryEmail].trim().toLowerCase() || "",
        streetAddress: studentRecordData[fieldRef.streetAddress].trim() || "",
        apartmentNumber: studentRecordData[fieldRef.apartmentNumber].trim() || "",
        primaryPhone: studentRecordData[fieldRef.primaryPhone.trim() || ""],
        secondaryPhone: studentRecordData[fieldRef.secondaryPhone].trim() || "",
      };
      const existingCaregiverIndex = caregiverArray.findIndex(
        (caregiver) =>
          caregiver.email?.toLowerCase() === tempObj.email.toLowerCase() ||
          caregiver.primaryPhone.replace(/\D/g, "") === tempObj.primaryPhone.replace(/\D/g, "")
      );
      if (existingCaregiverIndex > -1) {
        caregiverArray[existingCaregiverIndex].studentIds.push(studentRecord.id);
      } else {
        caregiverArray.push(tempObj);
      }
    });
    caregiverArray.forEach(async (caregiver) => {
      const existingCaregiverRecord = existingCaregivers.findIndex((existingCaregiver) => {
        return (
          caregiver.email === existingCaregiver.email ||
          caregiver.primaryPhone.replace(/\D/g, "") ===
            existingCaregiver.primaryPhone.replace(/\D/g, "")
        );
      });
      if (existingCaregiverRecord === -1) {
        const newCollectionRef = collection(db, "caregivers");
        const newCaregiverId = await addDoc(newCollectionRef, caregiver);
        caregiver.studentIds.forEach((studentId) => {
          const studentDocRef = doc(db, "students", studentId);
          updateDoc(studentDocRef, { primaryCaregiverId: newCaregiverId.id });
        });
      }
    });
  };

  return (
    <>
      <Box sx={{ margin: 4 }}>
        <Typography variant="h5">
          Click to add primary caregivers in student records to a caregiver collection and add their
          new primaryCaregiverId to the students collection.
        </Typography>
        <Button sx={{ mr: 2 }} onClick={addCaregiversFromStudents} variant="contained">
          Add Primary Caregivers From Students
        </Button>
      </Box>
    </>
  );
};

export default AddPrimaryCaregiversFromStudents;
