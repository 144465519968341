import { useState, useEffect, SyntheticEvent } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { studentsResetAtom } from "../../recoil/studentAtoms";
import { loggedInStaffAtom } from "../../recoil/staffAtoms";
import { TextField, Button, FormControlLabel, Switch, Box } from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { Timestamp } from "firebase/firestore";
import { PERMISSIONS } from "../../libraries/objects";
import { CommentInterface, StudentRecord } from "../../interfaces/interfaces";

const blankForm: CommentInterface = {
  text: "",
  authorEmail: "",
  adminOnly: false,
  authorName: "",
  timestamp: null,
  id: 0,
};

type FormState = EventTarget & {
  name: string;
  value: string;
  checked: boolean;
};

type Props = {
  selectedStudent: StudentRecord;
  setSelectedStudent: (value: StudentRecord | null) => void;
};

export default function NewComment({ selectedStudent, setSelectedStudent }: Props) {
  const setStudentsReset = useSetRecoilState(studentsResetAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const [newCommentForm, setNewCommentForm] = useState<CommentInterface>(blankForm);

  useEffect(() => {
    if (!loggedInStaff || !loggedInStaff?.id) return;
    setNewCommentForm({ ...newCommentForm, authorEmail: loggedInStaff.id });
  }, [loggedInStaff, newCommentForm]);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (!loggedInStaff || !loggedInStaff?.id) return;
    const allComments = selectedStudent.comments ? [...selectedStudent.comments] : [];
    allComments.push({ ...newCommentForm, timestamp: Timestamp.now() });
    await updateDoc({ col: "students", id: selectedStudent.id, data: { comments: allComments } });
    setSelectedStudent({ ...selectedStudent, comments: allComments });
    setStudentsReset((pV) => !pV);
    setNewCommentForm({ ...blankForm, authorEmail: loggedInStaff.id });
  };

  const updateInteraction = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setNewCommentForm((pV) => ({ ...pV, text: formState.value }));
  };

  const setAdminOnly = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setNewCommentForm((pV) => ({ ...pV, adminOnly: formState.checked }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        required
        type="text"
        style={{ marginTop: "1em" }}
        id="commInput"
        name="commInput"
        label="Internal Facing Comments"
        value={newCommentForm.text}
        onChange={updateInteraction}
        multiline
        rows={4}
      />
      {loggedInStaff && loggedInStaff.sitePermissions?.includes(PERMISSIONS.SEE_ADMIN_COMMENTS) && (
        <FormControlLabel
          control={<Switch onChange={setAdminOnly} checked={newCommentForm.adminOnly || false} />}
          label="Make Comment Only Visible to Administrators?"
        />
      )}
      <Box>
        <Button variant="contained" type="submit" sx={{ mt: 2, mb: 2 }}>
          Submit
        </Button>
      </Box>
    </form>
  );
}
