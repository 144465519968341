import { Select, MenuItem, Typography, SelectChangeEvent } from "@mui/material";
import { FieldInterface, Student } from "../../interfaces/interfaces";

type Props = {
  field: FieldInterface;
  form: Student;
  setForm: (value: Student) => void;
};

export default function Dropdown({ field, form, setForm }: Props) {
  let options = field?.options;
  const handleChange = (event: SelectChangeEvent) => {
    setForm({ ...form, [field.id]: event.target.value });
  };

  return (
    <>
      <Typography variant="body2">{field.question}</Typography>
      <Select
        value={String(form[field.id])}
        onChange={handleChange}
        fullWidth
        required={field.required}
      >
        {options?.map((c) => (
          <MenuItem key={c} value={c}>
            {c}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
