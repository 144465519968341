import { Typography, Grid } from "@mui/material";
import Question from "./Question";
import AdditionalContactsContainer from "./AdditionalContactsContainer";
import { SectionInterface, Student } from "../../interfaces/interfaces";

type Props = {
  section: SectionInterface;
  form: Student;
  setForm: (value: Student | null) => void;
};

export default function Section({ section, form, setForm }: Props) {
  return (
    <>
      <Typography sx={{ mt: 3, mb: 2 }} variant="h4">
        {section.name}
      </Typography>
      <Grid container spacing={2}>
        {section.fields &&
          section.fields.map((field) => (
            <Question
              key={field.id}
              field={field}
              form={form}
              setForm={setForm}
            />
          ))}
      </Grid>
      {section.id === "ZVbcADfEuSHbXKXeUf12" && (
        <AdditionalContactsContainer form={form} setForm={setForm} />
      )}
    </>
  );
}
