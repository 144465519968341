import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ChangeLog } from "../../interfaces/interfaces";

type Props = {
  logs: ChangeLog[];
};

function ChangeLogContainer({ logs }: Props) {
  return (
    <>
      {logs.length > 0 && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Edit History</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pl: 2, pr: 2, pt: 0 }}>
              {logs.map((log) => (
                <Box key={log.id}>
                  {log.message.split("\n").map((str) => (
                    <Typography variant="body2" key={str}>
                      {str}
                    </Typography>
                  ))}
                  <Divider sx={{ mt: 1, mb: 1 }} />
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </>
  );
}

export default ChangeLogContainer;
