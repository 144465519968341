import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Comment from "./Comment";
import EditCommentDialog from "./EditCommentDialog";
import DeleteCommentDialog from "./DeleteCommentDialog";
import { CommentInterface, StudentRecord } from "../../interfaces/interfaces";
import NewComment from "./NewComment";

type Props = {
  commentsArray: CommentInterface[];
  setSelectedStudent: (value: StudentRecord | null) => void;
  selectedStudent: StudentRecord;
};

export default function CommentsContainer({
  commentsArray,
  setSelectedStudent,
  selectedStudent,
}: Props) {
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [comments, setComments] = useState<CommentInterface[]>([]);

  useEffect(() => {
    if (commentsArray?.length > 0) {
      const tempComments = [...commentsArray];
      tempComments.sort((a: CommentInterface, b: CommentInterface) => {
        if (!a.timestamp || !b.timestamp) return 0;
        return b.timestamp.seconds - a.timestamp.seconds;
      });
      setComments(tempComments);
    }
  }, [commentsArray]);

  return (
    <Box sx={{pr: 4}}>
      <Typography variant="h5">Comments: </Typography>
      <NewComment selectedStudent={selectedStudent} setSelectedStudent={setSelectedStudent} />
      {comments ? (
        comments.map((comment, index) => (
          <Comment
            key={index + comment.text}
            comment={comment}
            index={index}
            setIndex={setSelectedIndex}
            setDeleteOpen={setDeleteOpen}
            setEditOpen={setEditOpen}
          />
        ))
      ) : (
        <Typography> There are No Recorded Comments </Typography>
      )}
      <DeleteCommentDialog
        index={selectedIndex}
        setIndex={setSelectedIndex}
        open={deleteOpen}
        setOpen={setDeleteOpen}
        comments={comments}
        setSelectedStudent={setSelectedStudent}
        selectedStudent={selectedStudent}
      />
      <EditCommentDialog
        index={selectedIndex}
        setIndex={setSelectedIndex}
        open={editOpen}
        setOpen={setEditOpen}
        comments={comments}
        setSelectedStudent={setSelectedStudent}
        selectedStudent={selectedStudent}
      />
    </Box>
  );
}
