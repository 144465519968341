import { atom, selector } from "recoil";
import {
  DepartmentsInterface,
  FieldInterface,
  SectionInterface,
} from "../interfaces/interfaces";
import { sectionsAndFieldsGetter, registrationFormGetter } from "./selectors";

//****** DEPARTMENTS ATOMS ******//

export const departmentsAtom = atom<DepartmentsInterface[]>({
  key: "departments",
  default: [],
});

//****** SECTIONS AND FIELDS ATOMS ******//

export const fieldsAtom = atom<FieldInterface[]>({
  key: "fields",
  default: [],
});

export const sectionsAtom = atom<SectionInterface[]>({
  key: "sections",
  default: [],
});

export const sectionsAndFieldsAtom = selector({
  key: "sectionsAndFields",
  get: sectionsAndFieldsGetter,
});

export const sectionsAndFieldsResetAtom = atom({
  key: "sectionsAndFieldsReset",
  default: false,
});

export const selectRegistrationFields = selector({
  key: "registrationFields",
  get: registrationFormGetter,
});
