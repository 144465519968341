import React from "react";
import {
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions,
  Dialog,
} from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { CommentInterface, StudentRecord } from "../../interfaces/interfaces";

type Props = {
  index: number | null;
  setIndex: (value: number | null) => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  setSelectedStudent: (value: StudentRecord | null) => void;
  selectedStudent: StudentRecord | null;
  comments: CommentInterface[];
};

//TODO: When you delete a comment, the "last comment" field in the application collection doesn't get updated
export default function DeleteCommentDialog({
  index,
  setIndex,
  open,
  setOpen,
  comments,
  setSelectedStudent,
  selectedStudent,
}: Props) {
  const { sendRequest: updateDoc } = useUpdateDoc();

  const handleClose = () => {
    setOpen(false);
    setIndex(null);
  };

  const handleDelete = () => {
    if (!selectedStudent || !index) return;
    comments.splice(index, 1);
    updateDoc({ col: "students", id: selectedStudent.id, data: { comments: comments } });
    setSelectedStudent({ ...selectedStudent, comments: comments });
    setIndex(null);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this Interaction?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This can not be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Nevermind</Button>
          <Button variant="contained" onClick={handleDelete} autoFocus>
            Yes, I want to delete this interaction
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
