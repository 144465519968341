import { useState } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { ROLLUP_MENU_SECTIONS } from "../../libraries/objects";
import ClassSummaryContainer from "./ClassSummaryContainer";
import RollupSideMenu from "./RollupSideMenu";

export default function RollupContainer() {
  const [activeRollupSection, setActiveRollupSection] = useState<string>(
    ROLLUP_MENU_SECTIONS.CLASS_SUMMARY
  );
  const sideMenuHeight = window.innerHeight - 64;
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <Box sx={{ height: sideMenuHeight, backgroundColor: "#eee" }}>
            <RollupSideMenu setActiveRollupSection={setActiveRollupSection} />
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ height: sideMenuHeight, overflow: "scroll", pl: 2, pr: 2 }}>
            <Typography sx={{ textAlign: "center", pt: 2, marginBottom: "8px" }} variant="h2">
              Rollups
            </Typography>
            {activeRollupSection === ROLLUP_MENU_SECTIONS.CLASS_SUMMARY && (
              <>
                <ClassSummaryContainer />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
