import { Box } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import RollupContainer from "../components/Rollups/RollupContainer";

const RollupPage = () => {
  return (
    <>
      <Navbar />
      <Box sx={{ minHeight: "100vh", mt: 8 }}>
        <RollupContainer />
      </Box>
    </>
  );
};

export default RollupPage;
