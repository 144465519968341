import { SyntheticEvent } from "react";
import { Box, TextField } from "@mui/material";
import EnrollmentStatusSelect from "./EnrollmentStatusSelect";

type FormState = EventTarget & {
  name: string;
  value: string;
};

type Props = {
  setSearchTerm: (value: string) => void;
  searchTerm: string;
  selectedEnrollStatus: string;
  setSelectedEnrollStatus: (value: string) => void;
};

function SearchBar({
  searchTerm,
  selectedEnrollStatus,
  setSelectedEnrollStatus,
  setSearchTerm,
}: Props) {
  const handleSearchTermChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setSearchTerm(formState.value);
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1, padding: 2 }}>
      <Box>
        <TextField
          placeholder={"Student Search"}
          value={searchTerm}
          onChange={handleSearchTermChange}
          fullWidth
        />
      </Box>
      <Box>
        <EnrollmentStatusSelect
          selectedEnrollStatus={selectedEnrollStatus}
          setSelectedEnrollStatus={setSelectedEnrollStatus}
        />
      </Box>
    </Box>
  );
}

export default SearchBar;
