import { useEffect, useContext } from "react";
import useGetDocs from "./useGetDocs";
import { useSetRecoilState, useRecoilValue } from "recoil";
import {
  departmentsAtom,
  sectionsAndFieldsResetAtom,
  sectionsAtom,
  fieldsAtom,
} from "../recoil/atoms";
import {
  staffAtom,
  loggedInStaffAtom,
  staffResetAtom,
  staffChangeLogsAtom,
} from "../recoil/staffAtoms";
import { villagesAtom, villagesResetAtom } from "../recoil/villageAtoms";
import { gradesAtom, gradesResetAtom } from "../recoil/gradeAtoms";
import { homeroomsAtom, homeroomsResetAtom, homeroomChangeLogsAtom } from "../recoil/homeroomAtoms";
import { studentsAtom, studentsResetAtom } from "../recoil/studentAtoms";
import {
  ChangeLog,
  DepartmentsInterface,
  FieldInterface,
  GradeRecord,
  HomeroomRecord,
  SectionInterface,
  StaffRecord,
  StudentRecord,
  VillageRecord,
} from "../interfaces/interfaces";
import { AuthContext } from "../providers/AuthProvider";
import {
  parseChangeLogsResponse,
  parseDepartmentsResponse,
  parseFieldResponse,
  parseGradesResponse,
  parseHomeroomResponse,
  parseSectionResponse,
  parseStaffResponse,
  parseVillagesResponse,
} from "../libraries/parsers";

const useBootstrapEffect = () => {
  const { sendRequest: getDocs } = useGetDocs();
  const { currentAuthUser } = useContext(AuthContext);
  const staff = useRecoilValue(staffAtom);

  //******SETTERS******/
  const setStaff = useSetRecoilState<StaffRecord[]>(staffAtom);
  const setGrades = useSetRecoilState<GradeRecord[]>(gradesAtom);
  const setHomerooms = useSetRecoilState<HomeroomRecord[]>(homeroomsAtom);
  const setLoggedInStaff = useSetRecoilState<StaffRecord | null>(loggedInStaffAtom);
  const setDepartments = useSetRecoilState<DepartmentsInterface[]>(departmentsAtom);
  const setSections = useSetRecoilState<SectionInterface[]>(sectionsAtom);
  const setFields = useSetRecoilState<FieldInterface[]>(fieldsAtom);
  const setStudents = useSetRecoilState<StudentRecord[]>(studentsAtom);
  const setHomeroomChangeLogs = useSetRecoilState<ChangeLog[]>(homeroomChangeLogsAtom);
  const setStaffChangeLogs = useSetRecoilState<ChangeLog[]>(staffChangeLogsAtom);
  const setVillages = useSetRecoilState<VillageRecord[]>(villagesAtom);

  //******RESETS******/
  const homeroomsReset = useRecoilValue(homeroomsResetAtom);
  const staffReset = useRecoilValue(staffResetAtom);
  const sectionsAndFieldsReset = useRecoilValue(sectionsAndFieldsResetAtom);
  const studentsReset = useRecoilValue(studentsResetAtom);
  const villagesReset = useRecoilValue(villagesResetAtom);
  const gradesReset = useRecoilValue(gradesResetAtom);

  useEffect(() => {
    const getStaff = async () => {
      const response = await getDocs<StaffRecord>({ col: "staff" });
      if (response) {
        setStaff(parseStaffResponse(response));
      }
    };
    getStaff();
  }, [setStaff, getDocs, staffReset]);

  useEffect(() => {
    const getStudents = async () => {
      const response = await getDocs<StudentRecord>({ col: "students" });
      if (response) {
        setStudents(response);
      }
    };
    getStudents();
  }, [setStudents, getDocs, studentsReset]);

  useEffect(() => {
    const getHomerooms = async () => {
      const response = await getDocs<HomeroomRecord>({ col: "homerooms" });
      if (response) {
        setHomerooms(parseHomeroomResponse(response));
      }
    };
    getHomerooms();
  }, [setHomerooms, getDocs, homeroomsReset]);

  useEffect(() => {
    const getDepartments = async () => {
      const response = await getDocs<DepartmentsInterface>({ col: "departments" });
      if (response) {
        setDepartments(parseDepartmentsResponse(response));
      }
    };
    getDepartments();
  }, [setDepartments, getDocs]);

  useEffect(() => {
    const getVillages = async () => {
      const response = await getDocs<VillageRecord>({
        col: "villages",
        config: { orderBy: ["order"] },
      });
      if (response) {
        setVillages(parseVillagesResponse(response));
      }
    };
    getVillages();
  }, [setVillages, getDocs, villagesReset]);

  useEffect(() => {
    const getFields = async () => {
      const response = await getDocs<FieldInterface>({
        col: "fields",
        config: { orderBy: ["order", "asc"] },
      });
      if (response) {
        setFields(parseFieldResponse(response));
      }
    };
    getFields();
  }, [setFields, getDocs, sectionsAndFieldsReset]);

  useEffect(() => {
    const getSections = async () => {
      const response = await getDocs<SectionInterface>({
        col: "sections",
        config: { orderBy: ["order", "asc"] },
      });
      if (response) {
        setSections(parseSectionResponse(response));
      }
    };
    getSections();
  }, [setSections, getDocs, sectionsAndFieldsReset]);

  useEffect(() => {
    if (
      !currentAuthUser ||
      staff.length === 0 ||
      currentAuthUser.providerData[0].providerId !== "google.com"
    ) {
      return;
    }
    const filteredStaff = staff.find((staffMember) => staffMember.id === currentAuthUser.email);
    if (filteredStaff) {
      setLoggedInStaff(filteredStaff);
    } else {
      console.error(
        "[Hook] useBootstrapEffect - filtered Staff, no DB user found or multiple DB users found",
        currentAuthUser.email
      );
    }
  }, [currentAuthUser, staff, setLoggedInStaff, staffReset]);

  useEffect(() => {
    const getGrades = async () => {
      const response = await getDocs<GradeRecord>({
        col: "grades",
        config: { orderBy: ["order"] },
      });
      if (response) {
        setGrades(parseGradesResponse(response));
      }
    };
    getGrades();
  }, [setGrades, getDocs, gradesReset]);

  useEffect(() => {
    const getHomeroomChangeLogs = async () => {
      const response = await getDocs<ChangeLog>({
        col: "homeroomChangeLogs",
        config: { orderBy: ["createdAt", "desc"] },
      });
      if (response) {
        setHomeroomChangeLogs(parseChangeLogsResponse(response));
      }
    };
    getHomeroomChangeLogs();
  }, [setHomeroomChangeLogs, getDocs, homeroomsReset]);

  useEffect(() => {
    const getStaffChangeLogs = async () => {
      const response = await getDocs<ChangeLog>({
        col: "staffChangeLogs",
        config: { orderBy: ["createdAt", "desc"] },
      });
      if (response) {
        setStaffChangeLogs(parseChangeLogsResponse(response));
      }
    };
    getStaffChangeLogs();
  }, [setStaffChangeLogs, getDocs, staffReset]);
};

export default useBootstrapEffect;
