import { Box, Paper, Typography, Button } from "@mui/material";
import React from "react";
import { useSetRecoilState } from "recoil";
import { GradeRecord } from "../../interfaces/interfaces";
import { gradeFormAtom } from "../../recoil/gradeAtoms";

type Props = {
  grade: GradeRecord;
  setManageOpen: (value: boolean) => void;
  setDeleteOpen: (value: boolean) => void;
  setDeleteId: (value: string | null) => void;
};

function GradeCard({ grade, setManageOpen, setDeleteOpen, setDeleteId }: Props) {
  const setGradeForm = useSetRecoilState(gradeFormAtom);
  const handleFieldEdit = (grade: GradeRecord) => {
    setGradeForm(grade);
    setManageOpen(true);
  };

  const handleDeleteGrade = () => {
    setDeleteOpen(true);
    setDeleteId(grade.id);
  };
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Paper sx={{ padding: 2, display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">{`Grade Name: ${grade.name}`}</Typography>
          <Box>
            <Button sx={{ mr: 2 }} variant="outlined" onClick={() => handleFieldEdit(grade)}>
              Edit
            </Button>
            <Button variant="outlined" color="error" onClick={handleDeleteGrade}>
              Delete
            </Button>
          </Box>
        </Paper>
      </Box>
    </>
  );
}

export default GradeCard;
