import { Button, Paper, Typography } from "@mui/material";
import React from "react";
import { StudentRecord } from "../../interfaces/interfaces";
import { fieldRef } from "../../libraries/objects";

type Props = {
  student: StudentRecord;
  setSelectedStudent: (value: StudentRecord) => void;
};

function StudentCard({ student, setSelectedStudent }: Props) {
  const handleEditClick = () => {
    setSelectedStudent(student);
  };
  return (
    <>
      <Paper sx={{ padding: 2, textAlign: "center" }}>
        <Typography variant="h6">{`${student[fieldRef.childFirstName]} ${
          student[fieldRef.childLastName]
        }`}</Typography>
        <Button onClick={handleEditClick} sx={{ mt: 2 }} variant="outlined">
          Edit
        </Button>
      </Paper>
    </>
  );
}

export default StudentCard;
