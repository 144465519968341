import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import EmailNotVerified from "./EmailNotVerified";
import StudentCardsList from "./StudentCardsList";

function CaregiverHomeContainer() {
  const [verified, setVerified] = useState(false);
  const { currentAuthUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentAuthUser) return;
    if (currentAuthUser.emailVerified) {
      setVerified(true);
    }
  }, [currentAuthUser]);

  return <>{verified ? <StudentCardsList /> : <EmailNotVerified />}</>;
}

export default CaregiverHomeContainer;
