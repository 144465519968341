import React, { SyntheticEvent } from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import { villageFormAtom, villagesResetAtom } from "../../recoil/villageAtoms";
import { Button, TextField, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import useAddDoc from "../../hooks/useAddDoc";
import { BLANK_VILLAGE_FORM } from "../../libraries/blankForms";
import DialogTitle from "@mui/material/DialogTitle";

type Props = {
  open: boolean;
  setOpen: (value: boolean | ((pV: boolean) => boolean)) => void;
};

type FormState = EventTarget & {
  name: string;
  value: string;
  checked: string;
};

export default function ManageVillage({ open, setOpen }: Props) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const { sendRequest: addDoc } = useAddDoc();
  const [villageForm, setVillageForm] = useRecoilState(villageFormAtom);
  const setVillagesReset = useSetRecoilState(villagesResetAtom);

  const handleChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setVillageForm((pV) => {
      return { ...pV, [formState.name]: formState.value };
    });
  };

  const handleClose = () => {
    setOpen(false);
    setVillageForm(BLANK_VILLAGE_FORM);
  };

  const handleSave = async () => {
    if ("id" in villageForm) {
      await updateDoc({ col: "villages", data: villageForm, id: villageForm.id });
    } else {
      await addDoc({ col: "villages", data: villageForm });
    }
    handleClose();
    setVillageForm(BLANK_VILLAGE_FORM);
    setVillagesReset((pV) => !pV);
  };

  return (
    <>
      {villageForm && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Village</DialogTitle>
          <DialogContent>
            <Typography sx={{ mb: 1 }}>Name</Typography>
            <TextField name="name" value={villageForm.name} onChange={handleChange} />
            <Typography sx={{ mb: 1 }}>Order</Typography>
            <TextField
              type="number"
              name="order"
              value={villageForm.order}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
