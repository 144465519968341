import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableFilters from "./TableFilters";
import SearchBar from "./SearchBar";

type Props = {
  filteredFields: string[];
  setFilteredFields: (value: string[] | ((prevVar: string[]) => string[])) => void;
  setSearchTerm: (value: string) => void;
  searchTerm: string;
  selectedEnrollStatus: string;
  setSelectedEnrollStatus: (value: string) => void;
};

function FiltersAccordion({
  filteredFields,
  setFilteredFields,
  searchTerm,
  setSearchTerm,
  selectedEnrollStatus,
  setSelectedEnrollStatus,
}: Props) {
  return (
    <>
      <Box sx={{mt: 2, mb: 2}}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{height: "40px !important", margin: "0 !important", minHeight: "0px !important"}}>
            <Typography variant="h6">Filters</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{pl: 2, pr: 2, pt: 0}}>
            <SearchBar
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              selectedEnrollStatus={selectedEnrollStatus}
              setSelectedEnrollStatus={setSelectedEnrollStatus}
            />
            <TableFilters filteredFields={filteredFields} setFilteredFields={setFilteredFields} />
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

export default FiltersAccordion;
