import { atom } from "recoil";
import { StudentRecord } from "../interfaces/interfaces";

export const studentsAtom = atom<StudentRecord[]>({
  key: "students",
  default: [],
});

export const studentsResetAtom = atom({
  key: "studentsReset",
  default: false,
});

export const showFabIconAtom = atom<string | null>({
  key: "showFabIcon",
  default: null,
});

export const selectedDialogSectionAtom = atom<string | null>({
  key: "selectedDialogSection",
  default: null,
});
