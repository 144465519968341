import { useState, useEffect, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { staffAtom, loggedInStaffAtom } from "../../recoil/staffAtoms";
import { Fab, Typography, FormControlLabel, Switch } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StaffDialog from "./StaffDialog";
import StaffTable from "./StaffTable";
import { PERMISSIONS } from "../../libraries/objects";
import { StaffRecord } from "../../interfaces/interfaces";

const StaffContainer = () => {
  const staff = useRecoilValue(staffAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const [filteredStaff, setFilteredStaff] = useState<StaffRecord[]>();
  const [showTermed, setShowTermed] = useState(false);
  const [manageOpen, setManageOpen] = useState(false);
  const userPermissions = loggedInStaff ? loggedInStaff.sitePermissions : [];

  const handleEditOpen = useCallback(() => {
    setManageOpen(true);
  }, [setManageOpen]);

  useEffect(() => {
    if (!staff) return;
    if (showTermed) {
      setFilteredStaff(staff);
    } else {
      setFilteredStaff(staff.filter((s) => s.terminated === false));
    }
  }, [staff, showTermed]);

  const toggleTermed = useCallback(() => {
    setShowTermed((pV) => !pV);
  }, []);

  return (
    <>
      {loggedInStaff && (
        <>
          <Typography sx={{ mt: 2, mb: 1 }} variant="h2">
            Staff
          </Typography>
          {userPermissions.includes(PERMISSIONS.SEE_TERMED_STAFF) && (
            <FormControlLabel
              control={<Switch onChange={toggleTermed} />}
              label="Include Termminated Staff"
            />
          )}
          {filteredStaff && <StaffTable staff={filteredStaff} setOpen={setManageOpen} />}
          {userPermissions.includes(PERMISSIONS.EDIT_STAFF) && (
            <Fab
              sx={{ position: "fixed", bottom: "50px", right: "50px" }}
              color="primary"
              aria-label="add"
              onClick={handleEditOpen}
            >
              <AddIcon />
            </Fab>
          )}
          {filteredStaff && <StaffDialog open={manageOpen} setOpen={setManageOpen} />}
        </>
      )}
    </>
  );
};

export default StaffContainer;
