import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

type Props = {
  value: string;
  setValue: (value: string) => void;
};

export default function DateEdit({ value, setValue }: Props) {
  const [dtValue, setDtValue] = useState<DateTime | null>(null);

  useEffect(() => {
    if (value) {
      setDtValue(DateTime.fromFormat(value, "D"));
    } else {
      setDtValue(null);
    }
  }, [value]);

  const handleChange = (newValue: DateTime | null) => {
    setValue(newValue?.toFormat("D") ?? "");
    setDtValue(newValue);
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker value={dtValue} onChange={handleChange} />
      </LocalizationProvider>
    </>
  );
}
