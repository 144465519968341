import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { sectionsAndFieldsAtom } from "../../recoil/atoms";
import { loggedInStaffAtom } from "../../recoil/staffAtoms";
import { Dialog, Grid, Box, Typography } from "@mui/material";
import AdditionalContactDialog from "./AdditionalContactDialog";
import FieldEditDialog from "../EditStudentField/FieldEditDialog";
import DeleteContactDialog from "./DeleteContactDialog";
import { fieldRef, PERMISSIONS } from "../../libraries/objects";
import { FieldInterface, StudentRecord } from "../../interfaces/interfaces";
import SideMenu from "./SideMenu";
import SectionsRouter from "./SectionsRouter";

type Props = {
  selectedStudent: StudentRecord;
  setSelectedStudent: (value: StudentRecord | null) => void;
};

export default function StudentDialogContainer({ selectedStudent, setSelectedStudent }: Props) {
  const sectionsAndFields = useRecoilValue(sectionsAndFieldsAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const [additionalContactOpen, setAdditionalContactOpen] = useState(false);
  const [selectedField, setSelectedField] = useState<FieldInterface | null>(null);
  const [additionalContactIndex, setAdditionalContactIndex] = useState<number | null>(null);
  const [deleteContactConfirmation, setDeleteContactConfirmation] = useState(false);

  const handleClose = () => {
    setSelectedStudent(null);
  };

  useEffect(() => {
    if (
      !loggedInStaff?.sitePermissions?.includes(PERMISSIONS.SEE_ADMIN_COMMENTS) &&
      selectedStudent.comments
    ) {
      console.log("test");
      const comments = selectedStudent.comments.filter((comment) => !comment.adminOnly);
      setSelectedStudent({ ...selectedStudent, comments: comments });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedStudent, loggedInStaff]);

  return (
    <>
      {sectionsAndFields && (
        <Dialog
          maxWidth="xl"
          fullWidth={true}
          open={selectedStudent ? true : false}
          sx={{ margin: window.innerWidth * 0.004 }}
          PaperProps={{ sx: { height: "80vh" } }}
          onClose={handleClose}
        >
          <Box sx={{ textAlign: "center", backgroundColor: "#B930E6", padding: 1, color: "white" }}>
            <Typography variant="h4">{`${selectedStudent[fieldRef.childFirstName]} ${
              selectedStudent[fieldRef.childLastName]
            }`}</Typography>
          </Box>
          <Grid container sx={{ height: "100%" }}>
            <Grid item xs={12} sm={3} md={3}>
              <SideMenu />
            </Grid>
            <Grid item xs={12} sm={9} md={9}>
              <Box sx={{ mt: 2, ml: 4 }}>
                <SectionsRouter
                  selectedStudent={selectedStudent}
                  setSelectedStudent={setSelectedStudent}
                  selectedField={selectedField}
                  setSelectedField={setSelectedField}
                  setAdditionalContactOpen={setAdditionalContactOpen}
                  setAdditionalContactIndex={setAdditionalContactIndex}
                  setDeleteContactConfirmation={setDeleteContactConfirmation}
                />
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      )}
      <AdditionalContactDialog
        open={additionalContactOpen}
        setOpen={setAdditionalContactOpen}
        selectedStudent={selectedStudent}
        setSelectedStudent={setSelectedStudent}
        additionalContactIndex={additionalContactIndex}
        setAdditionalContactIndex={setAdditionalContactIndex}
      />
      {selectedField && (
        <FieldEditDialog
          selectedField={selectedField}
          selectedStudent={selectedStudent}
          setSelectedStudent={setSelectedStudent}
          setSelectedField={setSelectedField}
        />
      )}
      <DeleteContactDialog
        open={deleteContactConfirmation}
        setOpen={setDeleteContactConfirmation}
        selectedStudent={selectedStudent}
        setSelectedStudent={setSelectedStudent}
        index={additionalContactIndex}
        setIndex={setAdditionalContactIndex}
      />
    </>
  );
}
