import { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { loggedInStaffAtom } from "../../recoil/staffAtoms";
import { selectedHomeroomAtom, homeroomsAtom } from "../../recoil/homeroomAtoms";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { PERMISSIONS } from "../../libraries/objects";
import { HomeroomRecord } from "../../interfaces/interfaces";

export default function HomeroomSelect() {
  const [selectedHomeroom, setSelectedHomeroom] = useRecoilState(selectedHomeroomAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const homerooms = useRecoilValue(homeroomsAtom);
  const [staffHomerooms, setStaffHomerooms] = useState<HomeroomRecord[] | null>(null);

  useEffect(() => {
    if (!loggedInStaff) return;
    if (!loggedInStaff.sitePermissions?.includes(PERMISSIONS.SEE_ALL_ROSTERS)) {
      setStaffHomerooms(homerooms.filter((hr) => loggedInStaff.homerooms.includes(hr?.id ?? "")));
    }
  }, [loggedInStaff, homerooms]);

  useEffect(() => {
    if (!loggedInStaff || !homerooms || selectedHomeroom) return;
    if (loggedInStaff.sitePermissions?.includes(PERMISSIONS.SEE_ALL_ROSTERS)) {
      setSelectedHomeroom("all");
    } else if (staffHomerooms && staffHomerooms.length > 0) {
      setSelectedHomeroom(staffHomerooms[0].id);
    }
  }, [loggedInStaff, homerooms, selectedHomeroom, staffHomerooms, setSelectedHomeroom]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedHomeroom(event.target.value);
  };

  return (
    <>
      {loggedInStaff && selectedHomeroom && selectedHomeroom !== "none" && (
        <Select
          id="select-homeroom"
          sx={{ color: "white", fontWeight: 800, borderColor: "#fff", maxWidth: "30vw" }}
          value={selectedHomeroom}
          label="Homeroom"
          onChange={handleChange}
        >
          {loggedInStaff.sitePermissions?.includes(PERMISSIONS.SEE_ALL_ROSTERS) && (
            <MenuItem value="all">All</MenuItem>
          )}
          {loggedInStaff.sitePermissions?.includes(PERMISSIONS.SEE_ALL_ROSTERS) &&
            homerooms &&
            homerooms.map((homeroom) => (
              <MenuItem key={homeroom?.id ?? ""} value={homeroom?.id ?? ""}>
                {homeroom.name}
              </MenuItem>
            ))}
          {!loggedInStaff.sitePermissions?.includes(PERMISSIONS.SEE_ALL_ROSTERS) &&
            staffHomerooms &&
            staffHomerooms.map((homeroom) => (
              <MenuItem key={homeroom?.id ?? ""} value={homeroom?.id ?? ""}>
                {homeroom.name}
              </MenuItem>
            ))}
        </Select>
      )}
    </>
  );
}
