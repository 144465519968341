import { Typography, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useRecoilValue } from "recoil";
import { StaffFormAtom } from "../../recoil/staffAtoms";

type Props = {
  handleSelectChange: (event: SelectChangeEvent) => void;
};

function PTOPolicySelect({ handleSelectChange }: Props) {
  const staffForm = useRecoilValue(StaffFormAtom);
  return (
    <>
      <Typography sx={{ mt: 2 }} variant="body2">
        Select PTO Policy
      </Typography>
      <Select
        id="select-ptoPolicy"
        name="ptoPolicy"
        value={staffForm.ptoPolicy}
        variant="outlined"
        fullWidth
        onChange={handleSelectChange}
      >
        <MenuItem key="10-Month" value="10-Month">
          10 Month Exempt
        </MenuItem>
        <MenuItem key="10-Month-Non-Exempt" value="10-Month-Non-Exempt">
          10 Month Non-Exempt
        </MenuItem>
        <MenuItem key="12-Month" value="12-Month">
          12 Month
        </MenuItem>
        <MenuItem key="12-Month-Leadership" value="12-Month-Leadership">
          12 Month Leadership
        </MenuItem>
        <MenuItem key="No Policy" value="No Policy">
          No PTO Policy
        </MenuItem>
      </Select>
    </>
  );
}

export default PTOPolicySelect;
