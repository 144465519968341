import { useState } from "react";
import { Box, Button } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { gradesAtom, gradesResetAtom } from "../../recoil/gradeAtoms";
import GradeCard from "./GradeCard";
import ManageGrade from "./ManageGrade";
import DeleteDialog from "../shared/DeleteDialog";

function GradesContainer() {
  const grades = useRecoilValue(gradesAtom);
  const [manageOpen, setManageOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const setGradesReset = useSetRecoilState(gradesResetAtom);

  const handleManageClick = () => {
    setManageOpen(true);
  };
  return (
    <>
      <Box sx={{ mt: 2, ml: 4, mr: 4 }}>
        <Button
          onClick={handleManageClick}
          fullWidth
          variant="contained"
          color="secondary"
          sx={{ padding: 1, fontSize: 16 }}
        >
          Add New Grade
        </Button>
        {grades &&
          grades.map((grade) => (
            <GradeCard
              key={grade.id}
              grade={grade}
              setManageOpen={setManageOpen}
              setDeleteOpen={setDeleteOpen}
              setDeleteId={setDeleteId}
            />
          ))}
      </Box>
      <ManageGrade open={manageOpen} setOpen={setManageOpen} />
      {deleteId && deleteOpen && (
        <DeleteDialog
          open={deleteOpen}
          setOpen={setDeleteOpen}
          message={"Are you sure you want to delete this grade? This can not be undone."}
          collection="grades"
          id={deleteId}
          setReset={setGradesReset}
        />
      )}
    </>
  );
}

export default GradesContainer;
