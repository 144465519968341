import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";

type Props = {
  id: string | boolean;
  open: boolean | string;
  setDeleteSectionId: (value: boolean | string) => void;
};

export default function DeleteSectionConfirmation({ id, open, setDeleteSectionId }: Props) {
  const { sendRequest: updateDoc } = useUpdateDoc();

  const handleDelete = () => {
    if (typeof id !== "string") return;
    updateDoc({ col: "sections", id: id, data: { active: false } }); //fields
    setDeleteSectionId(false);
  };

  const handleClose = () => {
    setDeleteSectionId(false);
  };

  return (
    <>
      {id && (
        <Dialog open={Boolean(open)} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>Section</DialogTitle>
          <DialogContent>Are you sure you want to delete this Section?</DialogContent>
          <DialogActions>
            <Button color="error" onClick={handleDelete}>
              Delete
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
