import { useCallback } from "react";
import { DateTime } from "luxon";
import { useRecoilState } from "recoil";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaffFormAtom } from "../../recoil/staffAtoms";

type Props = {
  field: "termDate" | "startDate";
};

export default function StaffDatePicker({ field }: Props) {
  const [staffForm, setStaffForm] = useRecoilState(StaffFormAtom);
  const value = DateTime.fromFormat(staffForm[field], "D");

  const updateDate = useCallback(
    (newValue: DateTime | null) => {
      const newDate = newValue ? newValue.toLocaleString() : "";
      setStaffForm((pV) => ({ ...pV, [field]: newDate }));
    },
    [setStaffForm, field]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DatePicker value={value} onChange={updateDate} />
    </LocalizationProvider>
  );
}
