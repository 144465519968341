import React, { useState, useContext } from "react";
import { Paper, Typography, Box, Button, Alert } from "@mui/material";
import { sendEmailVerification } from "firebase/auth";
import { AuthContext } from "../../providers/AuthProvider";

function EmailNotVerified() {
  const { currentAuthUser } = useContext(AuthContext);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const handleClick = async () => {
    if (!currentAuthUser) return;
    await sendEmailVerification(currentAuthUser, {url: "http://localhost:3000/caregiver"});
    setSuccessMessage(
      `An email has been sent to ${currentAuthUser.email}. Be sure to check your spam folder if you do not see the email within then next minute.`
    );
  };

  const handleResetMessage = () => {
    setSuccessMessage(null);
  };
  return (
    <>
      <Box sx={{ mt: 8 }}>
        <Paper sx={{ padding: 4, textAlign: "center" }}>
          <Typography variant="h6" textAlign="center">
            Your email has not been verified. Please check your inbox and your spam folder. If you
            do not see the verification email, click below to resend it.
          </Typography>
          {Boolean(successMessage) && <Alert onClose={handleResetMessage}>{successMessage}</Alert>}
          <Button size="large" sx={{ mt: 4 }} variant="contained" onClick={handleClick}>
            RESEND VERIFICATION EMAIL
          </Button>
        </Paper>
      </Box>
    </>
  );
}

export default EmailNotVerified;
