import { useState } from "react";
import { Container, Divider, Typography } from "@mui/material";
import NavBar from "../components/Navigation/Navbar";
import AddActiveToCollection from "./AddActiveToCollection";
import PasswordProtections from "./PasswordProtections";
import ConvertTimestampToCreatedAt from "./ConvertTimestampToCreatedAt";
import AddPrimaryCaregiversFromStudents from "./AddPrimaryCaregiversFromStudents";
import DeletePrimaryCaregiverIdField from "./DeletePrimaryCaregiverIdField";
import AddAdditionalContactsFromStudents from "./AddAdditionalContactsFromStudents";
import IncreaseGradeLevel from "./IncreaseGradeLevel";

function DBChanges() {
  const [passwordApproved, setPasswordApproved] = useState(true);
  return (
    <>
      <NavBar></NavBar>
      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: 8, pt: 2 }}>
        <Typography>
          First, run Convert Timestamp To CreatedAt for fields, homerooms, staff, students, and
          sections.
        </Typography>
        <Divider />
        <Typography>
          Then, run primary caregivers from students button. It will create a caregiver entry for
          every primary caregiver and add the id of that new caregiver to the student it came from.
        </Typography>
        <Typography>
          Then, run add additiona contacts from Students
        </Typography>
        {passwordApproved ? (
          <>
            <AddActiveToCollection />
            <ConvertTimestampToCreatedAt />
            <AddPrimaryCaregiversFromStudents />
            <DeletePrimaryCaregiverIdField />
            <AddAdditionalContactsFromStudents />
          </>
        ) : (
          <PasswordProtections setPasswordApproved={setPasswordApproved} />
        )}
        <IncreaseGradeLevel />
      </Container>
    </>
  );
}

export default DBChanges;
