import { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./providers/AuthProvider";

interface CustomRouteProps {
  children: React.ReactNode;
  permission: string;
  allowedUserTypes: string[];
}

const CustomRoute = ({ children, permission, allowedUserTypes }: CustomRouteProps) => {
  const { loading, currentAuthUser } = useContext(AuthContext);
  const [allowed, setAllowed] = useState(false);
  const [userType, setUserType] = useState<"staff" | "caregiver" | null>(null);

  useEffect(() => {
    if (!currentAuthUser || loading) return;
    const tempUserType =
      currentAuthUser.providerData[0].providerId === "google.com" ? "staff" : "caregiver";
    if (allowedUserTypes.includes(tempUserType)) {
      setAllowed(true);
    }
    setUserType(tempUserType);
  }, [currentAuthUser, allowedUserTypes, loading]);

  return (
    <>
      {currentAuthUser && allowed && !loading && children}
      {userType && currentAuthUser && !allowed && !loading && userType === "caregiver" && (
        <Navigate to="/caregiver" />
      )}
      {!currentAuthUser && !allowed && !loading && <Navigate to="/login" />}
    </>
  );
};

export default CustomRoute;
