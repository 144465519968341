import { atom } from "recoil";
import { Grade, GradeRecord } from "../interfaces/interfaces";
import { BLANK_GRADE_FORM } from "../libraries/blankForms";

export const gradesAtom = atom<GradeRecord[]>({
  key: "grades",
  default: [],
});

export const gradeFormAtom = atom<Grade | GradeRecord>({
  key: "gradeForm",
  default: BLANK_GRADE_FORM,
});

export const gradesResetAtom = atom({
  key: "gradesReset",
  default: false,
});
