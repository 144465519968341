import { atom } from "recoil";
import { HomeroomRecord, Homeroom, ChangeLog } from "../interfaces/interfaces";
import { BLANK_HOMEROOM_FORM } from "../libraries/blankForms";

export const homeroomsAtom = atom<HomeroomRecord[]>({
  key: "homerooms",
  default: [],
});

export const homeroomsResetAtom = atom({
  key: "homeroomsReset",
  default: false,
});

export const selectedHomeroomAtom = atom<string | null>({
  key: "selectedHomeroom",
  default: null,
});

export const homeroomFormAtom = atom<HomeroomRecord | Homeroom>({
  key: "homeroomForm",
  default: BLANK_HOMEROOM_FORM,
});

export const homeroomChangeLogsAtom = atom<ChangeLog[]>({
  key: "homeroomChangeLogs",
  default: [],
});
