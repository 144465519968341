import React, { SyntheticEvent } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { sectionsAndFieldsResetAtom } from "../../recoil/atoms";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Box,
  FormControlLabel,
  Switch,
} from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import useAddDoc from "../../hooks/useAddDoc";
import { blankSectionForm } from "../../libraries/objects";

import DialogTitle from "@mui/material/DialogTitle";
import { SectionInterface } from "../../interfaces/interfaces";

type Props = {
  sectionForm: SectionInterface;
  setSectionForm: (value: SectionInterface | ((pV: SectionInterface) => SectionInterface)) => void;
  open: boolean;
  setOpen: (value: boolean | ((pV: boolean) => boolean)) => void;
};

type FormState = EventTarget & {
  name: string;
  value: string;
  checked: string;
};

export default function ManageSectionDialog({ sectionForm, setSectionForm, open, setOpen }: Props) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const { sendRequest: addDoc } = useAddDoc();
  const setSectionsAndFieldsReset = useSetRecoilState(sectionsAndFieldsResetAtom);
  const sectionsAndFieldsReset = useRecoilValue(sectionsAndFieldsResetAtom);

  const handleChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setSectionForm((pV) => {
      return { ...pV, [formState.name]: formState.value };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    if (sectionForm.id) {
      await updateDoc({ col: "sections", data: sectionForm, id: sectionForm.id });
    } else {
      await addDoc({ col: "sections", data: sectionForm });
    }
    handleClose();
    setSectionForm(blankSectionForm);
    setSectionsAndFieldsReset(!sectionsAndFieldsReset);
  };

  const handleSwitchChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setSectionForm((pV) => {
      return { ...pV, [formState.name]: formState.checked };
    });
  };

  return (
    <>
      {sectionForm && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Section</DialogTitle>
          <DialogContent>
            <Typography sx={{ mb: 1 }}>Name</Typography>
            <TextField name="name" value={sectionForm.name} onChange={handleChange} />
            <Typography sx={{ mb: 1 }}>Order</Typography>
            <TextField
              type="number"
              name="order"
              value={sectionForm.order}
              onChange={handleChange}
            />
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    name="showCaregiver"
                    checked={sectionForm.showCaregiver}
                    onChange={handleSwitchChange}
                  />
                }
                label="Allow Caregivers To Manage"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
