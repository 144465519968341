import React from "react";
import Typography from "@mui/material/Typography";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FieldInterface, Student } from "../../interfaces/interfaces";
import { DateTime } from "luxon";

type Props = {
  field: FieldInterface;
  form: Student;
  setForm: (value: Student) => void;
};

export default function Date({ field, form, setForm }: Props) {
  let dateValue: DateTime | null = null;
  if (form[field.id]) {
    dateValue = DateTime.fromFormat(form[field.id] as string, "D");
  }
  const handleChange = (newValue: DateTime | null) => {
    const formattedDate = newValue ? newValue.toFormat("D") : "";
    setForm({ ...form, [field.id]: formattedDate });
  };

  return (
    <React.Fragment>
      <Typography variant="body2">{field.question}</Typography>

      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker value={dateValue} onChange={handleChange} />
      </LocalizationProvider>
    </React.Fragment>
  );
}
