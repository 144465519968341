import { SyntheticEvent, useState } from "react";
import { Button, Alert, Typography, Box, AlertColor } from "@mui/material";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { StudentRecord } from "../../interfaces/interfaces";

type Props = {
  selectedStudent: StudentRecord;
  setSelectedStudent: (value: StudentRecord | null) => void;
};

type ImageState = EventTarget & {
  files: File[];
};

export default function FileUpload({ selectedStudent, setSelectedStudent }: Props) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [message, setMessage] = useState<{ type: AlertColor; text: string } | null>(null);
  const storage = getStorage();
  const { sendRequest: updateDoc } = useUpdateDoc();

  const changeHandler = (event: SyntheticEvent) => {
    const imageState = event.target as ImageState;
    setSelectedFile(imageState.files[0]);
  };

  const handleSubmission = async () => {
    if (!selectedFile) return;
    const timestamp = Number(new Date());
    const fileName = timestamp + selectedFile.name;
    const storageRef = ref(storage, `${selectedStudent.id}/${fileName}`);
    const results = await uploadBytes(storageRef, selectedFile);
    if (results.metadata.timeCreated) {
      setMessage({ type: "success", text: "File Successfully Uploaded" });
    } else {
      setMessage({
        type: "error",
        text: "There was an error uploading your file, please try again",
      });
    }
    const files = selectedStudent.files ? [...selectedStudent.files] : [];
    files.push(fileName);
    console.log(files, selectedStudent.id);
    updateDoc({ col: "students", id: selectedStudent.id, data: { files: files } });
    setSelectedStudent({ ...selectedStudent, files: files });
  };

  return (
    <div>
      {message && <Alert severity={message.type}>{message.text}</Alert>}
      <Button variant="contained" component="label" sx={{ mt: 2 }}>
        Select File to Upload
        <input type="file" name="file" onChange={changeHandler} hidden />
      </Button>
      {selectedFile && (
        <Box sx={{ mt: 2 }}>
          <Typography>{selectedFile.name}</Typography>
          <Button sx={{ mt: 1 }} variant="outlined" onClick={handleSubmission}>
            Submit
          </Button>
        </Box>
      )}
    </div>
  );
}
