import { useState, useCallback } from "react";
import { doc, updateDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase";

interface SendRequestProps {
  col: string;
  data: {};
  id: string;
}

const useUpdateDoc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(
    async ({ col, data, id }: SendRequestProps): Promise<string | null> => {
      setIsLoading(true);
      setError(null);
      try {
        const finalData: any = { ...data, lastUpdated: Timestamp.now() };
        if (finalData?.id) {
          delete finalData.id;
        }
        const keys = Object.keys(finalData);
        keys.forEach((key) => {
          finalData[key] =
            typeof finalData[key] === "string" ? finalData[key].trim() : finalData[key];
        });

        const docRef = doc(db, col, id);
        await updateDoc(docRef, finalData);
        setIsLoading(false);
        return null;
      } catch (err: any) {
        setError(err.message || "Something went wrong!");
        setIsLoading(false);
        return null;
      }
    },
    []
  );
  return { isLoading, error, sendRequest };
};

export default useUpdateDoc;
