import { Paper, Typography, Box, Button } from "@mui/material";
import { FieldInterface } from "../../interfaces/interfaces";

type Props = {
  field: FieldInterface;
  handleFieldEdit: (field: FieldInterface) => void;
  setDeleteFieldId: (value: string) => void;
};

export default function Field({ field, handleFieldEdit, setDeleteFieldId }: Props) {
  const handleDeleteField = () => {
    setDeleteFieldId(field.id);
  };
  return (
    <Paper sx={{ mt: 2, padding: 1 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>{field.name}</Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button variant="outlined" onClick={() => handleFieldEdit(field)}>
            Edit
          </Button>
          <Button variant="outlined" color="error" onClick={handleDeleteField}>
            Delete
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
