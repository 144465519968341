import React from "react";
import { Typography, Divider, Box, Button } from "@mui/material";
import { Container, Draggable } from "react-smooth-dnd";
import Field from "./Field";
import { FieldInterface, SectionInterface, DropResult } from "../../interfaces/interfaces";

type Props = {
  section: SectionInterface;
  handleSectionEdit: (section: SectionInterface) => void;
  handleFieldEdit: (field: FieldInterface) => void;
  handleDrop: (dropResult: DropResult, index: number) => void;
  index: number;
  setDeleteFieldId: (value: boolean | string) => void;
  setDeleteSectionId: (value: boolean | string) => void;
};

const Section = ({
  section,
  handleSectionEdit,
  handleFieldEdit,
  handleDrop,
  index,
  setDeleteFieldId,
  setDeleteSectionId,
}: Props) => {
  const handleDeleteSection = () => {
    setDeleteSectionId(section.id);
  };


  const handleOnDrop = (dropResult: DropResult) => {
    handleDrop(dropResult, index);
  };
  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", padding: 1 }}>
        <Typography variant="h5">{section.name}</Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button color="secondary" variant="outlined" onClick={() => handleSectionEdit(section)}>
            Edit
          </Button>
          <Button color="error" variant="outlined" onClick={handleDeleteSection}>
            Delete
          </Button>
        </Box>
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Container
        style={{ minHeight: 40 }}
        lockAxis="y"
        getChildPayload={(i) => section.fields[i]}
        onDrop={handleOnDrop}
      >
        {section.fields &&
          section.fields.map((field) => (
            <Draggable key={field.id} className="overflowVisible">
              <Field
                key={field.id}
                field={field}
                handleFieldEdit={handleFieldEdit}
                setDeleteFieldId={setDeleteFieldId}
              />
            </Draggable>
          ))}
      </Container>
    </Box>
  );
};

export default React.memo(Section);
