import React, { useEffect, useState } from "react";
import { homeroomsAtom } from "../../recoil/homeroomAtoms";
import { useRecoilValue } from "recoil";
import { studentsAtom } from "../../recoil/studentAtoms";
import { fieldRef } from "../../libraries/objects";
import ClassSummaryTable from "./ClassSummaryTable";
import { Box } from "@mui/material";

type Row = {
  homeroomName: string;
  numberOfStudents: number;
  id: string;
  black: number;
  white: number;
  latinx: number;
  asian: number;
  indigenous: number;
  otherRace: number;
  male: number;
  female: number;
  otherGender: number;
};

function ClassSummaryContainer() {
  const homerooms = useRecoilValue(homeroomsAtom);
  const students = useRecoilValue(studentsAtom);
  const [rows, setRows] = useState<Row[]>([]);

  useEffect(() => {
    if (!homerooms || !students) return;
    const tempRows: Row[] = [];
    homerooms.forEach((homeroom) => {
      let black = 0;
      let white = 0;
      let latinx = 0;
      let male = 0;
      let female = 0;
      let otherGender = 0;
      let asian = 0;
      let indigenous = 0;
      let otherRace = 0;

      const filteredStudents = students.filter(
        (student) =>
          student[fieldRef.homeroom] === homeroom.id &&
          (student[fieldRef.enrollStatus] === "Actively Enrolled" ||
            student[fieldRef.enrollStatus] === "Pending Start")
      );
      filteredStudents.forEach((student) => {
        if (student[fieldRef.dataRace] === "Black") {
          black++;
        }
        if (student[fieldRef.dataRace] === "Latinx") {
          latinx++;
        }
        if (student[fieldRef.dataRace] === "Asian") {
          asian++;
        }
        if (student[fieldRef.dataRace] === "White") {
          white++;
        }
        if (student[fieldRef.dataRace] === "Other") {
          otherRace++;
        }
        if (student[fieldRef.dataRace] === "Indigenous") {
          indigenous++;
        }
        if (
          student[fieldRef.reportedGender]?.toString().toLowerCase().trimEnd().trimStart() ===
          "male"
        ) {
          male++;
        } else if (
          student[fieldRef.reportedGender]?.toString().toLowerCase().trimEnd().trimStart() ===
          "female"
        ) {
          female++;
        } else {
          otherGender++;
        }
      });
      tempRows.push({
        homeroomName: homeroom.name,
        numberOfStudents: filteredStudents.length,
        id: homeroom.id,
        black,
        white,
        indigenous,
        latinx,
        asian,
        otherRace,
        male,
        female,
        otherGender,
      });
    });
    setRows(tempRows);
  }, [students, homerooms]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <ClassSummaryTable rows={rows} />
      </Box>
    </>
  );
}

export default ClassSummaryContainer;
