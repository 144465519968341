import { useState, useCallback } from "react";
import { AppBar, Box, Toolbar, Typography, Button, IconButton, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../../providers/AuthProvider";
import Weblinks from "./Weblinks";
import { Link } from "react-router-dom";
import MobileLinks from "./MobileLinks";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import HomeroomSelect from "./HomeroomSelect";
import { useNavigate } from "react-router-dom";
import MenuDropdown from "./MenuDropdown";

const Navbar = () => {
  const { logout, currentAuthUser } = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { width } = useWindowDimensions();
  const collapse = width > 1000 ? false : true;
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography sx={{ flexGrow: 1, fontWeight: 700 }}>SIS</Typography>
          <HomeroomSelect />
          <>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1, ml: 1 }}
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
          </>
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuDropdown handleClose={handleClose} />
          </Menu>
          {currentAuthUser ? (
            <Button
              className="webLink"
              color="inherit"
              onClick={handleLogout}
              sx={{ fontWeight: 700 }}
            >
              LogOut
            </Button>
          ) : (
            <Link className="webLink" to="/login">
              LOGIN
            </Link>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
