import { SyntheticEvent } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { sectionsAndFieldsResetAtom } from "../../recoil/atoms";
import { Button, Dialog, DialogActions, DialogContent, SelectChangeEvent } from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import useAddDoc from "../../hooks/useAddDoc";
import { blankFieldForm } from "../../libraries/objects";

import DialogTitle from "@mui/material/DialogTitle";
import FieldsForm from "./FieldsForm";
import { FieldInterface, SectionInterface } from "../../interfaces/interfaces";

type Props = {
  fieldForm: FieldInterface;
  setFieldForm: (value: FieldInterface | ((pV: FieldInterface) => FieldInterface)) => void;
  open: boolean;
  setOpen: (value: boolean | ((pV: boolean) => boolean)) => void;
  sections: SectionInterface[];
};

type FormState = EventTarget & {
  name: string;
  value: string;
  checked: string;
};

export default function ManageFieldDialog({
  fieldForm,
  setFieldForm,
  open,
  setOpen,
  sections,
}: Props) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const { sendRequest: addDoc } = useAddDoc();
  const setSectionsAndFieldsReset = useSetRecoilState(sectionsAndFieldsResetAtom);
  const sectionsAndFieldsReset = useRecoilValue(sectionsAndFieldsResetAtom);

  const handleTextChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setFieldForm((pV) => {
      return { ...pV, [formState.name]: formState.value };
    });
  };

  const handleSwitchChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setFieldForm((pV) => {
      return { ...pV, [formState.name]: formState.checked };
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setFieldForm((pV) => {
      return { ...pV, [event.target.name]: event.target.value };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    if (fieldForm.id) {
      await updateDoc({ col: "fields", data: fieldForm, id: fieldForm.id });
    } else {
      await addDoc({ col: "fields", data: fieldForm });
    }
    handleClose();
    setFieldForm(blankFieldForm);
    setSectionsAndFieldsReset(!sectionsAndFieldsReset);
  };

  return (
    <>
      {fieldForm && (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>Field</DialogTitle>
          <DialogContent>
            <FieldsForm
              fieldForm={fieldForm}
              handleTextChange={handleTextChange}
              handleSelectChange={handleSelectChange}
              handleSwitchChange={handleSwitchChange}
              sections={sections}
              setFieldForm={setFieldForm}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
