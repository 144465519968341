import { SyntheticEvent, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectRegistrationFields } from "../../recoil/atoms";
import { studentsResetAtom } from "../../recoil/studentAtoms";
import {
  Typography,
  Paper,
  Button,
  Alert,
  FormControlLabel,
  Switch,
  CircularProgress,
  Box,
} from "@mui/material";
import Section from "./Section";
import { fieldRef, REGISTRATION_STATUSES } from "../../libraries/objects";
import useAddDoc from "../../hooks/useAddDoc";
import { DateTime } from "luxon";
import { MEDIA_RELEASE_MESSAGE } from "../../libraries/staticText";
import { Student } from "../../interfaces/interfaces";

type FormState = EventTarget & {
  name: string;
  value: string;
  checked: boolean;
};

export default function RegistrationFormContainer() {
  const registrationFields = useRecoilValue(selectRegistrationFields);
  const setStudentsReset = useSetRecoilState(studentsResetAtom);
  const [form, setForm] = useState<Student | null>(null);
  const [mediaRelease, setMediaRelease] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const { sendRequest: addDoc } = useAddDoc();
  const [blankForm, setBlankForm] = useState<Student | null>(null);


  useEffect(() => {
    if (!registrationFields || registrationFields.length === 0) return;
    const tempObj: Student = {
      timestamp: null,
      files: [],
      id: "",
      additionalContacts: [],
      comments: [],
    };
    registrationFields.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.id === fieldRef.birthday) {
          tempObj[field.id] = null;
        } else {
          tempObj[field.id] = "";
        }
      });
    });
    tempObj[fieldRef.enrollStatus] = REGISTRATION_STATUSES.pendingStart;
    tempObj[fieldRef.mediaRelease] = MEDIA_RELEASE_MESSAGE;
    const savedStringifyData: string | null = localStorage.getItem("tgp-form");
    if (savedStringifyData) {
      const savedObject = JSON.parse(savedStringifyData);
      setForm(savedObject);
    } else {
      setForm(tempObj);
    }
    setBlankForm(tempObj);
    setLoading(false);
  }, [registrationFields]);

  const resetSubmitted = () => {
    setSuccess(false);
  };

  const handleMediaReleaseSwitch = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setMediaRelease(formState.checked);
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    await addDoc({
      col: "students",
      data: { ...form, [fieldRef.submittedOn]: DateTime.now().toFormat("D") },
    });
    setSuccess(true);
    setForm(blankForm);
    setStudentsReset((pV) => !pV);
    window.scrollTo(0, 0);
    localStorage.removeItem("tgp-form");
  };
  return (
    <Paper sx={{ padding: 3, marginTop: 3 }}>
      <Typography sx={{ marginTop: 2, marginBottom: 5, textAlign: "Center" }} variant="h2">
        Registration Form
      </Typography>
      {success && <Alert onClose={resetSubmitted}>Your Form Was Submitted Successfully!</Alert>}
      {loading ? (
        <Box sx={{ display: "block", textAlign: "center" }}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <form onSubmit={handleSubmit}>
          {form &&
            registrationFields &&
            registrationFields.map((section) => (
              <Section
                key={section.id}
                section={section}
                form={form}
                setForm={setForm}
              />
            ))}
          <FormControlLabel
            sx={{ mt: 2 }}
            control={<Switch checked={mediaRelease} onChange={handleMediaReleaseSwitch} />}
            label={MEDIA_RELEASE_MESSAGE}
          />
          <Button
            sx={{ marginTop: 5, marginBottom: 5, fontSize: 18 }}
            fullWidth
            variant="contained"
            type="submit"
            disabled={false}
          >
            Submit
          </Button>
        </form>
      )}
    </Paper>
  );
}
