import { useState, useEffect, SyntheticEvent } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { CommentInterface, StudentRecord } from "../../interfaces/interfaces";

type Props = {
  index: number | null;
  setIndex: (value: number | null) => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  comments: CommentInterface[];
  setSelectedStudent: (value: StudentRecord) => void;
  selectedStudent: StudentRecord;
};

type FormState = EventTarget & {
  name: string;
  value: string;
};

export default function EditCommentDialog({
  index,
  setIndex,
  open,
  setOpen,
  comments,
  setSelectedStudent,
  selectedStudent,
}: Props) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const [commentForm, setCommentForm] = useState<CommentInterface | null>(null);

  useEffect(() => {
    if (!index) return;
    setCommentForm(comments[index]);
  }, [comments, index]);

  const handleEdit = (event: SyntheticEvent) => {
    if (!commentForm) return;
    const formState = event.target as FormState;
    setCommentForm({ ...commentForm, text: formState.value });
  };

  const handleClose = () => {
    setOpen(false);
    setIndex(null);
  };

  const handleSave = () => {
    if (!index || !commentForm) return;
    comments[index] = commentForm;
    updateDoc({ col: "students", id: selectedStudent.id, data: { comments: comments } });
    setSelectedStudent({ ...selectedStudent, comments: comments });
    setIndex(null);
    setOpen(false);
  };
  return (
    <>
      {commentForm && (
        <Dialog open={open} maxWidth={"md"} fullWidth>
          <DialogTitle>Edit Interaction</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              id="editInteraction"
              label="Interaction"
              type="text"
              fullWidth
              multiline
              value={commentForm.text}
              variant="standard"
              onChange={handleEdit}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
