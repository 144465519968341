import { Box, List, ListItem, ListItemButton, ListItemText, Divider } from "@mui/material";
import { ROLLUP_MENU_SECTIONS } from "../../libraries/objects";

type Props = {
    setActiveRollupSection: (newValue: string) => void;
};

export default function RollupSideMenu({ setActiveRollupSection }: Props) {
  return (
    <Box sx={{ width: "100%" }}>
      <nav>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setActiveRollupSection(ROLLUP_MENU_SECTIONS.CLASS_SUMMARY);
              }}
            >
              <ListItemText primary={ROLLUP_MENU_SECTIONS.CLASS_SUMMARY} />
            </ListItemButton>
          </ListItem>
          <Divider />
        </List>
      </nav>
    </Box>
  );
}
