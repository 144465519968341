import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { HomeroomRecord } from "../../interfaces/interfaces";

type Props = {
  value: string;
  setValue: (value: string) => void;
  options: HomeroomRecord[];
};

export default function HomeroomEdit({ value, setValue, options }: Props) {
  console.log(options);
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };
  return (
    <Select fullWidth value={value ? value : "Please Select One"} onChange={handleChange}>
      {options &&
        options.map((option) => (
          <MenuItem key={option?.id ?? ""} value={option?.id ?? ""}>
            {option.name}
          </MenuItem>
        ))}
    </Select>
  );
}
