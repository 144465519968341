import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Container, CircularProgress, Avatar, Box } from "@mui/material";
import Copyright from "./Copyright";
import { AuthContext } from "../../providers/AuthProvider";
import StaffLogin from "./StaffLogin";
import CaregiverLogin from "./CaregiverLogin";

const LoginContainer = () => {
  const [loginType, setLoginType] = useState<"staff" | "caregiver">("staff");
  const navigate = useNavigate();
  const { currentAuthUser, loading } = useContext(AuthContext);

  useEffect(() => {
    if (!loading && currentAuthUser) {
      if(currentAuthUser.providerData[0].providerId === "google.com"){
        console.log("A STAFF MEMBER IS LOGGED IN")
      navigate("/");
    }else{
      console.log("A CAREGIVER IS LOGGED IN")
      navigate("/caregiver")
    }
    }
  }, [currentAuthUser, navigate, loading]);

  return (
    <Container component="main" maxWidth="xs" sx={{ minHeight: "100vh", pt: 16 }}>
      {loading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 4,
            textAlign: "center",
          }}
        >
          <Avatar sx={{ width: 200, height: 200 }} src="./TGPIcon.png"></Avatar>
          <Typography variant="h4" sx={{ pt: 2 }}>
            Student Information System
          </Typography>
          {loginType === "staff" ? (
            <StaffLogin setLoginType={setLoginType} />
          ) : (
            <CaregiverLogin setLoginType={setLoginType} />
          )}
        </Paper>
      )}
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default LoginContainer;
