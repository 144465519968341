import { useState, useEffect } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { StudentRecord } from "../../interfaces/interfaces";
import { studentsResetAtom } from "../../recoil/studentAtoms";
import { Box, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { useSetRecoilState } from "recoil";

type Props = {
  selectedStudent: StudentRecord;
  setSelectedStudent: (value: StudentRecord) => void;
};

export default function FileList({ selectedStudent, setSelectedStudent }: Props) {
  const [urls, setUrls] = useState<{ url: string; name: string }[] | null>(null);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setStudentsReset = useSetRecoilState(studentsResetAtom);

  useEffect(() => {
    if (!selectedStudent) return;
    const getFileUrls = async () => {
      const tempArray: { url: string; name: string }[] = [];
      const storage = getStorage();

      await Promise.all(
        selectedStudent.files.map(async (file) => {
          const url = await getDownloadURL(ref(storage, `${selectedStudent.id}/${file}`));
          const name = file.slice((file.length - 13) * -1);
          tempArray.push({ url: url, name: name });
        })
      );
      setUrls(tempArray);
    };
    getFileUrls();
  }, [selectedStudent]);

  const handleClick = async (index: number) => {
    const files = [...selectedStudent.files];
    files.splice(index, 1);
    await updateDoc({ col: "students", id: selectedStudent.id, data: { files: files } });
    setSelectedStudent({ ...selectedStudent, files: files });
    setStudentsReset((pV) => !pV);
  };

  return (
    <>
      <Box sx={{ pr: 2 }}>
        <List>
          {urls &&
            urls.map((url, index) => (
              <ListItem disablePadding key={url.url} divider>
                <ListItemButton component="a" href={url.url} target="blank">
                  <ListItemText primary={url.name} />
                </ListItemButton>
                <DeleteIcon sx={{ cursor: "pointer" }} onClick={() => handleClick(index)} />
              </ListItem>
            ))}
        </List>
      </Box>
    </>
  );
}
