import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { staffChangeLogsAtom, StaffFormAtom, staffResetAtom } from "../../recoil/staffAtoms";
import { homeroomsAtom, homeroomsResetAtom } from "../../recoil/homeroomAtoms";
import { BLANK_STAFF_FORM } from "../../libraries/blankForms";
import useSetHomeroomStaffArray from "../../hooks/useSetHomeroomStaffArray";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import useStaffChangeLog from "../../hooks/useStaffChangeLog";
import useSetDoc from "../../hooks/useSetDoc";
import StaffForm from "./StaffForm";
import ChangeLogContainer from "../shared/ChangeLogContainer";
import { ChangeLog } from "../../interfaces/interfaces";

type Props = {
  open: boolean;
  setOpen: (prop: boolean) => void;
};

export default function StaffDialog({ open, setOpen }: Props) {
  const homerooms = useRecoilValue(homeroomsAtom);
  const setStaffReset = useSetRecoilState(staffResetAtom);
  const setHomeroomsReset = useSetRecoilState(homeroomsResetAtom);
  const [staffForm, setStaffForm] = useRecoilState(StaffFormAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const { sendRequest: setDoc } = useSetDoc();
  const { setStaffHomeroomArray } = useSetHomeroomStaffArray();
  const { addChangeLog } = useStaffChangeLog();
  const staffChangeLogs = useRecoilValue(staffChangeLogsAtom);
  const [filteredStaffChangeLogs, setFilteredStaffChangeLogs] = useState<ChangeLog[]>([]);

  useEffect(() => {
    if (!staffChangeLogs) return;
    setFilteredStaffChangeLogs(
      staffChangeLogs.filter((changeLog) => changeLog.recordId === staffForm.email)
    );
  }, [staffForm, staffChangeLogs]);

  const handleSave = async () => {
    setStaffHomeroomArray(homerooms, staffForm);
    if ("id" in staffForm) {
      await addChangeLog({ form: staffForm });
      await updateDoc({
        col: "staff",
        data: staffForm,
        id: staffForm.id,
      });
    } else {
      await setDoc({
        col: "staff",
        data: staffForm,
        id: staffForm.email,
      });
    }
    setStaffReset((pV) => !pV);
    setHomeroomsReset((pV) => !pV);
    handleClose();
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setStaffForm(BLANK_STAFF_FORM);
  }, [setOpen, setStaffForm]);
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{"id" in staffForm ? "Edit" : "New"} Staff</DialogTitle>
      <DialogContent>
        <StaffForm />
        <ChangeLogContainer logs={filteredStaffChangeLogs} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
