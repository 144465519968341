import { Typography, Grid, Box, Button } from "@mui/material";
import { useRecoilValue } from "recoil";
import { AdditionalContactInterface } from "../../interfaces/interfaces";
import { PERMISSIONS } from "../../libraries/objects";
import { loggedInStaffAtom } from "../../recoil/staffAtoms";

type Props = {
  additionalContact: AdditionalContactInterface;
  index: number;
  setAdditionalContactOpen: (value: boolean) => void;
  setAdditionalContactIndex: (value: number | null) => void;
  setDeleteContactConfirmation: (value: boolean) => void;
};

export default function AdditionalContactDisplay({
  additionalContact,
  index,
  setAdditionalContactOpen,
  setAdditionalContactIndex,
  setDeleteContactConfirmation,
}: Props) {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const handleEdit = () => {
    setAdditionalContactIndex(index);
    setAdditionalContactOpen(true);
  };

  const handleDelete = () => {
    setAdditionalContactIndex(index);
    setDeleteContactConfirmation(true);
  };
  return (
    <>
      <Typography variant="h5">Additional Contacts: </Typography>
      <Grid container key={additionalContact.phone + additionalContact.firstName}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ mt: 2, mb: 2 }} variant="h5">
              {`Additional Contact ${index + 1}`}{" "}
            </Typography>
            {loggedInStaff &&
              loggedInStaff.sitePermissions?.includes(PERMISSIONS.EDIT_STUDENTS) && (
                <>
                  <Button variant="outlined" color="secondary" sx={{ ml: 2 }} onClick={handleEdit}>
                    Edit
                  </Button>
                  <Button variant="outlined" color="error" sx={{ ml: 2 }} onClick={handleDelete}>
                    Delete
                  </Button>
                </>
              )}
          </Box>
          <Typography component="span" variant="body1">
            First Name:{" "}
          </Typography>
          <Typography component="span">{additionalContact.firstName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="span" variant="body1">
            Last Name:{" "}
          </Typography>
          <Typography component="span">{additionalContact.lastName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="span" variant="body1">
            Phone:{" "}
          </Typography>
          <Typography component="span">{additionalContact.phone}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="span" variant="body1">
            Email:{" "}
          </Typography>
          <Typography component="span">{additionalContact.email}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="span" variant="body1">
            Can They Pick Up:{" "}
          </Typography>
          <Typography component="span">{additionalContact.pickup}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="span" variant="body1">
            Relationship to Child:{" "}
          </Typography>
          <Typography component="span">{additionalContact.relationship}</Typography>
        </Grid>
      </Grid>
    </>
  );
}
