import { Route, Routes } from "react-router-dom";
import LoginContainer from "./components/Login/LoginContainer";
import StaffPage from "./pages/StaffPage";
import CustomRoute from "./CustomRoute";
import { PERMISSIONS } from "./libraries/objects";
import HomeroomsPage from "./pages/HomeroomsPage";
import SettingsPage from "./pages/SettingsPage";
import StudentTablePage from "./pages/StudentTablePage";
import RegistrationFormPage from "./pages/RegistrationFormPage";
import DBChanges from "./dbchanges/DBChanges";
import CaregiverPage from "./pages/CaregiverPage";
import RollupPage from "./pages/RollupPage";
import AccountabilityRosterPage from "./pages/AccountabilityRosterPage";

export const routes = (
  <Routes>
    <Route path="/login" element={<LoginContainer />} />
    <Route
      path="/staff"
      element={
        <CustomRoute permission={PERMISSIONS.EDIT_HOMEROOMS} allowedUserTypes={["staff"]}>
          <StaffPage />
        </CustomRoute>
      }
    />
    <Route
      path="/homerooms"
      element={
        <CustomRoute permission={PERMISSIONS.EDIT_HOMEROOMS} allowedUserTypes={["staff"]}>
          <HomeroomsPage />
        </CustomRoute>
      }
    />
    <Route
      path="/settings"
      element={
        <CustomRoute permission={PERMISSIONS.EDIT_HOMEROOMS} allowedUserTypes={["staff"]}>
          <SettingsPage />
        </CustomRoute>
      }
    />
    <Route
      path="/student-table"
      element={
        <CustomRoute permission={PERMISSIONS.EDIT_HOMEROOMS} allowedUserTypes={["staff"]}>
          <StudentTablePage />
        </CustomRoute>
      }
    />
    <Route
      path="/rollups"
      element={
        <CustomRoute permission={PERMISSIONS.EDIT_HOMEROOMS} allowedUserTypes={["staff"]}>
          <RollupPage />
        </CustomRoute>
      }
    />
    <Route
      path="/db-changes"
      element={
        <CustomRoute permission={PERMISSIONS.EDIT_HOMEROOMS} allowedUserTypes={["staff"]}>
          <DBChanges />
        </CustomRoute>
      }
    />
    <Route
      path="/"
      element={
        <CustomRoute permission={PERMISSIONS.EDIT_HOMEROOMS} allowedUserTypes={["staff"]}>
          <StaffPage />
        </CustomRoute>
      }
    />
    <Route
      path="/caregiver"
      element={
        <CustomRoute permission={PERMISSIONS.EDIT_HOMEROOMS} allowedUserTypes={["caregiver"]}>
          <CaregiverPage />
        </CustomRoute>
      }
    />
    <Route
      path="/accountability-roster"
      element={
        <CustomRoute permission={PERMISSIONS.EDIT_HOMEROOMS} allowedUserTypes={["staff"]}>
          <AccountabilityRosterPage />
        </CustomRoute>
      }
    />
    <Route path="/newRegistration" element={<RegistrationFormPage />} />
  </Routes>
);
