import { ColumnInterface } from "../interfaces/interfaces";
import { DateTime } from "luxon";
import { GridValueGetterParams, GridValueFormatterParams } from "@mui/x-data-grid-pro";

const getCellFormat = (params: GridValueFormatterParams) => {
  return DateTime.fromMillis(Number(params.value)).toFormat("D");
};

const getCellValue = (params: GridValueGetterParams) => {
  let dt;
  if (!params.value || params.value.length < 4) {
    dt = 0;
  } else {
    dt = DateTime.fromFormat(params.value, "D").toMillis();
  }
  return dt;
};

export const ClassSummaryColumns: ColumnInterface[] = [
  {
    field: "homeroomName",
    headerName: "Homeroom Name",
    flex: 2,
    minWidth: 100,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "numberOfStudents",
    headerName: "Total Students",
    flex: 1,
    minWidth: 50,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "latinx",
    headerName: "LatinX",
    flex: 1,
    minWidth: 50,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "black",
    headerName: "Black",
    flex: 1,
    minWidth: 50,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "white",
    headerName: "White",
    flex: 1,
    minWidth: 50,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "asian",
    headerName: "Asian",
    flex: 1,
    minWidth: 50,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "indigenous",
    headerName: "Indigenous",
    flex: 1,
    minWidth: 50,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "otherRace",
    headerName: "Other Race",
    flex: 1,
    minWidth: 50,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "female",
    headerName: "Female",
    flex: 1,
    minWidth: 50,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "male",
    headerName: "Male",
    flex: 1,
    minWidth: 50,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "otherGender",
    headerName: "Other Gender",
    flex: 1,
    minWidth: 50,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
];

export const staffColumns: ColumnInterface[] = [
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
    minWidth: 100,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "lastName",
    headerName: "Last Name",
    minWidth: 100,
    flex: 1,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "jobTitle",
    headerName: "Job Title",
    minWidth: 200,
    flex: 1,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "department",
    headerName: "Team",
    minWidth: 200,
    flex: 1,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "id",
    headerName: "Email",
    minWidth: 300,
    flex: 1,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "startDate",
    headerName: "Start Date",
    minWidth: 100,
    flex: 1,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    valueGetter: getCellValue,
    valueFormatter: getCellFormat,
    type: "number",
  },
  {
    field: "termDate",
    headerName: "End Date",
    minWidth: 100,
    flex: 1,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    valueGetter: getCellValue,
    valueFormatter: getCellFormat,
    type: "number",
  },
  {
    field: "ptoPolicy",
    headerName: "PTO Policy",
    minWidth: 100,
    flex: 1,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
];
