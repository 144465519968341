import { useState, useEffect } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { CircularProgress, Box, Typography } from "@mui/material";
import { GridToolbar, DataGridPro, GridRowParams } from "@mui/x-data-grid-pro";
import { ColumnInterface, StudentRecord } from "../../interfaces/interfaces";

type Props = {
  rows: StudentRecord[];
  columns: ColumnInterface[];
  setSelectedStudent: (
    value:
      | (StudentRecord | null | undefined)
      | ((pV: StudentRecord | null | undefined) => StudentRecord | null | undefined)
  ) => void;
};

export default function RosterTable({ rows, columns, setSelectedStudent }: Props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!rows || !columns) return;
    setLoading(false);
  }, [rows, columns]);

  const { height } = useWindowDimensions();

  const handleClick = (params: GridRowParams) => {
    setSelectedStudent(params.row);
  };

  return (
    <>
      {loading && (
        <Box sx={{ display: "block", textAlign: "center" }}>
          <CircularProgress size={50} />
        </Box>
      )}
      {rows && rows.length === 0 && (
        <Typography variant="h4" align="center">
          You are Not Currently Rostered to Any Classes
        </Typography>
      )}
      {rows && rows.length > 0 && columns && (
        <div style={{ height: height - 300, width: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <DataGridPro
              rows={rows}
              columns={columns}
              rowHeight={32}
              onRowClick={handleClick}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
