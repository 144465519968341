import { useState, useCallback } from "react";
import { Container, Button, Typography, Box } from "@mui/material";
import ManageSectionDialog from "./ManageSectionDialog";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  sectionsAtom,
  sectionsAndFieldsAtom,
  sectionsAndFieldsResetAtom,
} from "../../recoil/atoms";
import SectionsAndFields from "./SectionsAndFields";
import ManageFieldDialog from "./ManageFieldDialog";
import { blankSectionForm, blankFieldForm } from "../../libraries/objects";
import { updateDragArray } from "../../libraries/functions";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import DeleteFieldConfirmation from "./DeleteFieldConfirmation";
import DeleteSectionConfirmation from "./DeleteSectionConfirmation";
import { FieldInterface, SectionInterface, DropResult } from "../../interfaces/interfaces";

export default function SectionsAndFieldsContainer() {
  const [loading, setLoading] = useState(false);
  const [sectionForm, setSectionForm] = useState(blankSectionForm);
  const [fieldForm, setFieldForm] = useState(blankFieldForm);
  const [sectionOpen, setSectionOpen] = useState(false);
  const [fieldOpen, setFieldOpen] = useState(false);
  const [deleteFieldId, setDeleteFieldId] = useState<string | boolean>(false);
  const [deleteSectionId, setDeleteSectionId] = useState<string | boolean>(false);
  const sectionsAndFields = useRecoilValue(sectionsAndFieldsAtom);
  const sections = useRecoilValue(sectionsAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setSectionsAndFieldsReset = useSetRecoilState(sectionsAndFieldsResetAtom);

  const handleSectionOpen = () => {
    setSectionOpen(true);
  };

  const handleFieldOpen = () => {
    setFieldOpen(true);
  };

  const handleSectionEdit = useCallback((section: SectionInterface) => {
    setSectionForm(section);
    setSectionOpen(true);
  }, []);

  const handleFieldEdit = useCallback((field: FieldInterface) => {
    setFieldForm(field);
    setFieldOpen(true);
  }, []);

  const handleDrop = useCallback(
    async (dropResult: DropResult, index: number) => {
      setLoading(true);
      console.log("Started");
      if (!sectionsAndFields) return;
      const result = updateDragArray({ dropResult, arr: sectionsAndFields[index].fields });
      if (!result) {
        return;
      }
      const promises: Array<Promise<string | null>> = [];

      result.forEach((task, index) => {
        promises.push(updateDoc({ col: "fields", data: { order: index }, id: task.id }));
      });
      await Promise.all(promises);

      setSectionsAndFieldsReset((pV) => !pV);
      console.log("Ended");
      setLoading(false);
    },
    [sectionsAndFields, setSectionsAndFieldsReset, updateDoc]
  );

  return (
    <>
      {sectionsAndFields && (
        <Container maxWidth="md" sx={{ minHeight: "100vh" }}>
          <Box sx={{ pt: 2, display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4">Sections and Fields</Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
              <Button onClick={handleSectionOpen} variant="outlined">
                Create Section
              </Button>
              <Button onClick={handleFieldOpen} variant="outlined">
                Create Field
              </Button>
            </Box>
          </Box>
          <SectionsAndFields
            sectionsAndFields={sectionsAndFields}
            handleSectionEdit={handleSectionEdit}
            handleFieldEdit={handleFieldEdit}
            handleDrop={handleDrop}
            setDeleteFieldId={setDeleteFieldId}
            setDeleteSectionId={setDeleteSectionId}
            loading={loading}
          />

          {sectionForm && (
            <ManageSectionDialog
              open={sectionOpen}
              setOpen={setSectionOpen}
              sectionForm={sectionForm}
              setSectionForm={setSectionForm}
            />
          )}
          {deleteSectionId && (
            <DeleteSectionConfirmation
              id={deleteSectionId}
              open={deleteSectionId}
              setDeleteSectionId={setDeleteSectionId}
            />
          )}
          {fieldForm && (
            <ManageFieldDialog
              open={fieldOpen}
              setOpen={setFieldOpen}
              fieldForm={fieldForm}
              setFieldForm={setFieldForm}
              sections={sections}
            />
          )}
          {deleteFieldId && (
            <DeleteFieldConfirmation
              id={deleteFieldId}
              open={deleteFieldId}
              setDeleteFieldId={setDeleteFieldId}
            />
          )}
        </Container>
      )}
    </>
  );
}
