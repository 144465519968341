import { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { Button, DialogActions, DialogContent, DialogTitle, Dialog } from "@mui/material";
import { staffAtom, staffResetAtom } from "../../recoil/staffAtoms";
import {
  homeroomsResetAtom,
  homeroomFormAtom,
  homeroomChangeLogsAtom,
} from "../../recoil/homeroomAtoms";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import useAddDoc from "../../hooks/useAddDoc";
import useSetStaffHomeroomArray from "../../hooks/useSetStaffHomeroomArray";
import { BLANK_HOMEROOM_FORM } from "../../libraries/blankForms";
import HomeroomDialogForm from "./HomeroomDialogForm";
import useHomeroomChangeLog from "../../hooks/useHomeroomChangeLog";
import { ChangeLog } from "../../interfaces/interfaces";
import ChangeLogContainer from "../shared/ChangeLogContainer";

type Props = {
  open: boolean;
  setOpen: (prop: boolean) => void;
};

export default function HomeroomDialog({ open, setOpen }: Props) {
  const setHomeroomsReset = useSetRecoilState(homeroomsResetAtom);
  const setStaffReset = useSetRecoilState(staffResetAtom);
  const staff = useRecoilValue(staffAtom);
  const [homeroomForm, setHomeroomForm] = useRecoilState(homeroomFormAtom);
  const { addChangeLog } = useHomeroomChangeLog();
  const homeroomChangeLogs = useRecoilValue(homeroomChangeLogsAtom);
  const [changeLogs, setChangeLogs] = useState<ChangeLog[]>([]);

  useEffect(() => {
    if ("id" in homeroomForm) {
      const filteredChangeLogs = homeroomChangeLogs.filter(
        (changeLog) => changeLog.recordId === homeroomForm.id
      );
      if (!filteredChangeLogs) return;
      setChangeLogs(filteredChangeLogs);
    }
  }, [homeroomChangeLogs, homeroomForm]);

  const { sendRequest: updateDoc } = useUpdateDoc();
  const { sendRequest: addDoc } = useAddDoc();
  const { setStaffHomeroomArray } = useSetStaffHomeroomArray();

  const handleClose = () => {
    setOpen(false);
    setHomeroomForm(BLANK_HOMEROOM_FORM);
  };

  const saveHomeroom = async () => {
    if ("id" in homeroomForm) {
      setStaffHomeroomArray(staff, homeroomForm, homeroomForm.id);
      await addChangeLog({ form: homeroomForm });
      await updateDoc({
        col: "homerooms",
        data: homeroomForm,
        id: homeroomForm.id,
      });
    } else {
      const newId = await addDoc({ col: "homerooms", data: homeroomForm });
      if (newId) {
        setStaffHomeroomArray(staff, homeroomForm, newId);
      }
    }
    setHomeroomsReset((pV) => !pV);
    setStaffReset((pV) => !pV);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"id" in homeroomForm ? "Edit" : "New"} Homeroom</DialogTitle>
      <DialogContent>
        <HomeroomDialogForm />
        <ChangeLogContainer logs={changeLogs} />
      </DialogContent>
      <DialogActions>
        <Button onClick={saveHomeroom}>Save</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
