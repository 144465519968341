import { Box, InputLabel, MenuItem, FormControl, Select, SelectChangeEvent } from "@mui/material";

type Props = {
  selectedEnrollStatus: string;
  setSelectedEnrollStatus: (value: string) => void;
};

export default function EnrollmentStatusSelect({
  selectedEnrollStatus,
  setSelectedEnrollStatus,
}: Props) {
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedEnrollStatus(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 200 }}>
      <FormControl fullWidth>
        <InputLabel>Enrollment Status</InputLabel>
        <Select
          value={selectedEnrollStatus}
          label="Enrollment Status"
          fullWidth
          onChange={handleChange}
        >
          <MenuItem value={"Actively Enrolled"}>Active</MenuItem>
          <MenuItem value={"Pending Start"}>Pending Start</MenuItem>
          <MenuItem value={"Withdrawn"}>Withdrawn</MenuItem>
          <MenuItem value={"For Next Year"}>For Next Year</MenuItem>
          <MenuItem value={"Never Showed"}>Never Showed</MenuItem>
          <MenuItem value={"all"}>All</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
