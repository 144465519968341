import React, { SyntheticEvent } from "react";
import { TextField, Fab, Box } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { FieldInterface } from "../../interfaces/interfaces";

type Props = {
  option: string;
  index: number;
  setFieldForm: (value: FieldInterface | ((prevVar: FieldInterface) => FieldInterface)) => void;
};

type FormState = EventTarget & {
  name: string;
  value: string;
};

export default function SelectOption({ option, index, setFieldForm }: Props) {
  const handleOptionChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setFieldForm((pV) => {
      const tempOptions = pV.options ? [...pV.options] : ["Option"];
      tempOptions[index] = formState.value;
      return { ...pV, options: tempOptions };
    });
  };

  const handleAddOption = () => {
    setFieldForm((pV) => {
      const newOptions = [...pV.options, "Option"];
      return { ...pV, options: newOptions };
    });
  };

  const handleRemoveOption = () => {
    setFieldForm((pV) => {
      const newOptions = [...pV.options];
      newOptions.splice(index, 1);
      return { ...pV, options: newOptions };
    });
  };
  return (
    <Box sx={{ mt: 2, ml: 2, display: "flex", alignItems: "center" }}>
      <TextField value={option} onChange={handleOptionChange} />
      <Fab size="small" sx={{ ml: 2 }} color="secondary" onClick={handleAddOption}>
        <Add />
      </Fab>
      <Fab size="small" sx={{ ml: 2 }} onClick={handleRemoveOption}>
        <Remove />
      </Fab>
    </Box>
  );
}
