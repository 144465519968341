import { useState } from "react";
import { db } from "../firebase";
import { Button, Select, MenuItem, Box, Typography, SelectChangeEvent } from "@mui/material";
import { doc, getDocs, collection, updateDoc, setDoc } from "firebase/firestore";
import { COLLECTIONS, fieldRef } from "../libraries/objects";

const IncreaseGradeLevel = () => {
  const [selectedCollection, setSelectedCollection] = useState<string>("none");

  const returnNewGrade = (currentGradeLevel: string) => {
    switch (currentGradeLevel) {
      case "Kindergarten":
        return "1st Grade";
      case "1st Grade":
        return "2nd Grade";
      case "2nd Grade":
        return "3rd Grade";
      case "3rd Grade":
        return "4th Grade";
      case "4th Grade":
        return "5th Grade";
      default:
        return "none";
    }
  };

  const updateGradeLevel = async () => {
    const studentsCollectionRef = collection(db, "students");
    const snapshot = await getDocs(studentsCollectionRef);
    snapshot.forEach((student) => {
      const newStudentObj = { ...student.data() };
      if (newStudentObj[fieldRef.enrollStatus] !== "Actively Enrolled") return;
      const newGradeLevel = returnNewGrade(newStudentObj[fieldRef.currentGradeLevel]);
      console.log("Status", newStudentObj[fieldRef.enrollStatus]);
      const studentDocRef = doc(db, "students", student.id);
      updateDoc(studentDocRef, { [fieldRef.currentGradeLevel]: newGradeLevel });
    });
  };

  return (
    <>
      <Box sx={{ margin: 4 }}>
        <Button sx={{ mr: 2 }} onClick={updateGradeLevel} variant="contained">
          Increase Grade Level
        </Button>
      </Box>
    </>
  );
};

export default IncreaseGradeLevel;
