import { useState, useCallback } from "react";
import { Staff, HomeroomRecord } from "../interfaces/interfaces";
import useUpdateDoc from "./useUpdateDoc";

const useSetHomeroomStaffArray = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { sendRequest: updateDoc } = useUpdateDoc();

  const setStaffHomeroomArray = useCallback(
    (homerooms: HomeroomRecord[], staffForm: Staff) => {
      staffForm?.homerooms?.forEach((staffHomeroom) => {
        homerooms.forEach((homeroom) => {
          if (staffHomeroom === homeroom.id) {
            if (!homeroom.staff.includes(staffForm.email)) {
              updateDoc({
                col: "homerooms",
                id: homeroom.id,
                data: { staff: [...homeroom.staff, staffForm.email] },
              });
            }
          }
        });
      });
      homerooms.forEach((homeroom) => {
        if (!homeroom.id) return;
        if (
          homeroom.staff.includes(staffForm.email) &&
          !staffForm.homerooms.includes(homeroom.id)
        ) {
          const index = homeroom.staff.indexOf(staffForm.email);
          const newStaff = [...homeroom.staff];
          newStaff.splice(index, 1);
          updateDoc({ col: "homerooms", id: homeroom.id, data: { staff: newStaff } });
        }
      });
    },
    [updateDoc]
  );
  return { isLoading, error, setStaffHomeroomArray };
};

export default useSetHomeroomStaffArray;
