import React, { useEffect, useState } from "react";
import { StudentRecord } from "../../interfaces/interfaces";
import { useRecoilValue } from "recoil";
import { studentsAtom } from "../../recoil/studentAtoms";
import { selectedHomeroomAtom } from "../../recoil/homeroomAtoms";
import { fieldRef } from "../../libraries/objects";
import { Typography } from "@mui/material";

const AccountabilityRoster = () => {
  const [selectedStudents, setSelectedStudents] = useState<StudentRecord[]>([]);
  const [selectedEnrollStatus, setSelectedEnrollStatus] = useState("Actively Enrolled");
  const students = useRecoilValue(studentsAtom);
  const selectedHomeroom = useRecoilValue(selectedHomeroomAtom);

  //filter students by homeroom and enrollment Status
  useEffect(() => {
    if (!students || !selectedHomeroom) return;
    let studentsArray = [...students];
    if (selectedEnrollStatus !== "all") {
      studentsArray = studentsArray.filter((s) => s[fieldRef.enrollStatus] === "Actively Enrolled");
    }
    if (selectedHomeroom !== "all") {
      studentsArray = studentsArray.filter((s) => s[fieldRef.homeroom] === selectedHomeroom);
    }
    setSelectedStudents(studentsArray);
  }, [students, selectedHomeroom, selectedEnrollStatus]);
  return (
    <>
      <Typography align="center" variant="h4">
        Accountability Roster
      </Typography>
      <table style={{ width: "100%", border: "1px solid #000" }}>
        <thead>
          <tr>
            <td>First Name</td>
            <td>Last Name</td>
            <td>Warning</td>
            <td>Change of Location</td>
            <td>Caregiver Contact</td>
          </tr>
        </thead>
        <tbody>
          {selectedStudents.map((student) => (
            <tr>
              <td>{student[fieldRef.childFirstName] as string}</td>
              <td>{student[fieldRef.childLastName] as string}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default AccountabilityRoster;
