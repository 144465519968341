import { useState, useEffect, useContext } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { showFabIconAtom } from "../../recoil/studentAtoms";
import { loggedInStaffAtom } from "../../recoil/staffAtoms";
import { homeroomsAtom } from "../../recoil/homeroomAtoms";
import { Typography, Box } from "@mui/material";
import { fieldRef } from "../../libraries/objects";
import CustomEditIcon from "./CustomEditIcon";
import { PERMISSIONS } from "../../libraries/objects";
import { FieldInterface, StudentRecord } from "../../interfaces/interfaces";
import { HomeroomRecord } from "../../interfaces/interfaces";
import { AuthContext } from "../../providers/AuthProvider";

type Props = {
  field: FieldInterface;
  selectedStudent: StudentRecord;
  setSelectedField: (value: FieldInterface) => void;
};

export default function Field({ field, selectedStudent, setSelectedField }: Props) {
  const [showFabIcon, setShowFabIcon] = useRecoilState(showFabIconAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const homerooms = useRecoilValue(homeroomsAtom);
  const [currentHomeroom, setCurrentHomeroom] = useState<HomeroomRecord | null>(null);
  const { currentAuthUser } = useContext(AuthContext);

  const canEdit =
    currentAuthUser?.providerData[0].providerId === "google.com" ? true : field.caregiverCanEdit;

  useEffect(() => {
    if (!selectedStudent || !homerooms) return;
    const currentStudentHomeroom = homerooms.find(
      (homeroom) => homeroom.id === selectedStudent[fieldRef.homeroom]
    );
    if (currentStudentHomeroom) {
      setCurrentHomeroom(currentStudentHomeroom);
    }
  }, [selectedStudent, homerooms]);

  const handleMouseEnter = () => {
    if (currentAuthUser?.providerData[0].providerId === "google.com") {
      if (loggedInStaff && loggedInStaff.sitePermissions?.includes(PERMISSIONS.EDIT_STUDENTS)) {
        setShowFabIcon(field.id);
      }
    } else if (currentAuthUser?.providerData[0].providerId === "password") {
      setShowFabIcon(field.id);
    }
  };
  return (
    <>
      {homerooms && (
        <Box
          sx={{ mt: 1, display: "flex", justifyContent: "flex-start" }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={() => setShowFabIcon(null)}
        >
          {field.id !== fieldRef.homeroom && (
            //width keeps the edit icon from collapsing
            <Typography component="span" sx={{ maxWidth: "90%", fontSize: 17 }}>
              <>
                <b>{field.name}: </b>
                {selectedStudent[field.id]}
              </>
            </Typography>
          )}
          {field.id === fieldRef.homeroom && (
            <Typography component="span">
              <>
                <b>{field.name}: </b>
                {currentHomeroom?.name ?? "none"}
              </>
            </Typography>
          )}
          {showFabIcon === field.id && canEdit && (
            <CustomEditIcon setSelectedField={setSelectedField} field={field} />
          )}
        </Box>
      )}
    </>
  );
}
