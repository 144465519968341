import { useState, useCallback } from "react";
import { doc, updateDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase";
import { Staff, Homeroom, StaffRecord } from "../interfaces/interfaces";

const useSetStaffHomeroomArray = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const setStaffHomeroomArray = useCallback(
    (staff: StaffRecord[], homeroomForm: Homeroom, homeroomId: string) => {
      homeroomForm?.staff?.forEach((homroomStaff) => {
        staff.forEach((staffMember) => {
          if (!staffMember.id) return;
          const docRef = doc(db, "staff", staffMember.id);
          if (homroomStaff === staffMember.id) {
            if (!staffMember.homerooms.includes(homeroomId)) {
              updateDoc(docRef, {
                homerooms: [...staffMember.homerooms, homeroomId],
              });
            }
          }
        });
      });
      staff.forEach((staffMember) => {
        if (!staffMember.id) return;
        if (
          staffMember.homerooms.includes(homeroomId) &&
          !homeroomForm.staff.includes(staffMember.id)
        ) {
          const docRef = doc(db, "staff", staffMember.id);
          const index = staffMember.homerooms.indexOf(homeroomId);
          const newHomerooms = [...staffMember.homerooms];
          newHomerooms.splice(index, 1);
          updateDoc(docRef, {
            homerooms: newHomerooms,
          });
        }
      });
    },
    []
  );
  return { isLoading, error, setStaffHomeroomArray };
};

export default useSetStaffHomeroomArray;
