import { Grade, Homeroom, Staff, Village } from "../interfaces/interfaces";

export const BLANK_HOMEROOM_FORM: Homeroom = {
  name: "",
  gradeId: "",
  staff: [],
  villageId: "",
};

export const BLANK_STAFF_FORM: Staff = {
  department: "",
  firstName: "",
  lastName: "",
  homerooms: [],
  jobTitle: "",
  permission: "",
  ptoPolicy: "",
  startDate: "",
  termDate: "",
  uid: "",
  terminated: false,
  nonExempt: false,
  email: "",
  sitePermissions: [],
};

export const BLANK_VILLAGE_FORM: Village = {
  name: "",
  order: 0,
};

export const BLANK_GRADE_FORM: Grade = {
  name: "",
  order: 0,
};
