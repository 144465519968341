import React, { SyntheticEvent } from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import { gradeFormAtom, gradesResetAtom } from "../../recoil/gradeAtoms";
import { Button, TextField, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import useAddDoc from "../../hooks/useAddDoc";
import { BLANK_GRADE_FORM } from "../../libraries/blankForms";
import DialogTitle from "@mui/material/DialogTitle";

type Props = {
  open: boolean;
  setOpen: (value: boolean | ((pV: boolean) => boolean)) => void;
};

type FormState = EventTarget & {
  name: string;
  value: string;
  checked: string;
};

export default function ManageGrade({ open, setOpen }: Props) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const { sendRequest: addDoc } = useAddDoc();
  const [gradeForm, setGradeForm] = useRecoilState(gradeFormAtom);
  const setGradesReset = useSetRecoilState(gradesResetAtom);

  const handleChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setGradeForm((pV) => {
      return { ...pV, [formState.name]: formState.value };
    });
  };

  const handleClose = () => {
    setOpen(false);
    setGradeForm(BLANK_GRADE_FORM);
  };

  const handleSave = async () => {
    if ("id" in gradeForm) {
      await updateDoc({ col: "grades", data: gradeForm, id: gradeForm.id });
    } else {
      await addDoc({ col: "grades", data: gradeForm });
    }
    handleClose();
    setGradeForm(BLANK_GRADE_FORM);
    setGradesReset((pV) => !pV);
  };

  return (
    <>
      {gradeForm && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Grade</DialogTitle>
          <DialogContent>
            <Typography sx={{ mb: 1 }}>Name</Typography>
            <TextField name="name" value={gradeForm.name} onChange={handleChange} />
            <Typography sx={{ mb: 1 }}>Order</Typography>
            <TextField type="number" name="order" value={gradeForm.order} onChange={handleChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
